import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "@app/store";
import { Injury, InjuryPendingReason, InjuryStatus } from "@app/objects/Injury";
import * as PagesState from "@app/store/ItemList";
import { Field, FieldProps, Formik, FormikProps } from "formik";

import * as Yup from 'yup';
import Button from "@common/react/components/Forms/Button";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";

import * as ItemsState from "@common/react/store/ItemList";
import { StatusHistory } from "@app/objects/StatusHistory";
import { default as Datepicker, disabledTo } from "@common/react/components/Forms/Datepicker/Datepicker";
import { request } from '@app/components/Api';

interface OwnProps {
    injury: Injury;
    onSave: (item: StatusHistory) => void;
    item: StatusHistory | null;
    updateInjury: (oldInjury: Injury, newInjury: Injury) => void;
}

interface ReduxProps {
    injuryPendingReasons: PagesState.ItemsState<InjuryPendingReason>;
}

type Props = ReduxProps & OwnProps & { pendingReasonsActions: ActionCreatorsMapObject; }

interface FormValues {
    injuryPendingReasonId: number;
    note: string;
    followUpDate: number
}

interface ComponentState {
    isLoading: boolean
}

const validationSchema = Yup.object().shape({
    injuryPendingReasonId: Yup.number().min(1, 'You should set the number')
});

class PendingForm extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentWillMount() {
        this.props.pendingReasonsActions.reqPages('injuryPendingReasons', 'injuryPendingReasonList', 'injuryPendingReason', { page: 1, text: '', count: 100 });
    }


    handleSubmitForm(values: FormValues, { resetForm }) {

        this.setState({
            isLoading: true
        });

        let item;

        if (this.props.item) {
            item = { ...this.props.item, injury: null, outcomeType: null }
        } else {
            item = {
                id: -1,
                injuryStatus: InjuryStatus.Pending,
                injuryId: this.props.injury.id,
            };
        }

        item.note = values.note;

        if (item.pendingReasonId !== values.injuryPendingReasonId) {
            item.pendingReasonId = values.injuryPendingReasonId;
            item.pendingReason = this.props.injuryPendingReasons.items.find((item: InjuryPendingReason) => item.id === +values.injuryPendingReasonId)
        }
        //debugger;
        //this.props.updateInjury(this.props.injury, { ...this.props.injury, followUpDate: values.followUpDate });

        let InjItem = this.props.injury;
        InjItem.lastFollowUpDate = InjItem.followUpDate;
        InjItem.followUpDate = values.followUpDate;

        request<Injury>('injury', InjItem).then((result: Injury) => {

            request<StatusHistory>('statusHistory', item).then((result: StatusHistory) => {
                this.setState({
                    isLoading: false
                });

                resetForm();

                this.props.onSave(result);

            }).catch(() => {
                this.setState({
                    isLoading: false
                });
            });

        }).catch(() => {
            this.setState({
                isLoading: false
            });
        });
    }

    render() {
        const injuryPendingReasons = this.props.injuryPendingReasons.items,
            statusHistory = this.props.item;

        return <Formik
            initialValues={{
                injuryPendingReasonId: statusHistory ? (statusHistory.pendingReasonId || -1) : -1,
                note: statusHistory ? (statusHistory.note || '') : '',
                followUpDate: this.props.injury.followUpDate
            }}
            onSubmit={this.handleSubmitForm}
            enableReinitialize={true}
            validationSchema={validationSchema}
            render={(formikBag: FormikProps<FormValues>) => {
                return <div id={'pending-form'}>
                    <div className="injury-status-form form-group">
                        <div className="row">
                            <div
                                className={`form-group col-sm-6 ${formikBag.errors.injuryPendingReasonId && formikBag.touched.injuryPendingReasonId ? 'has-error' : ''}`}>
                                <label>Reason*</label>
                                <Field
                                    name="injuryPendingReasonId"
                                    render={({ field, form }: FieldProps<FormValues>) => (
                                        <div className="is-relative">
                                            <select className="form-control" id="recurrence" {...field}>
                                                <option disabled value={-1}>Select reason</option>
                                                {injuryPendingReasons && injuryPendingReasons.map((reason: InjuryPendingReason) => (
                                                    <option value={reason.id} key={reason.id}>{reason.name}</option>))
                                                }
                                            </select>
                                        </div>
                                    )}
                                />
                            </div>

                            <div className={`form-group col-sm-6`}>
                                <label>FollowUp Date*</label>
                                <Datepicker
                                    value={formikBag.values.followUpDate}
                                    format="MM/DD/YY"
                                    onChange={(date) => {
                                        formikBag.setFieldValue('followUpDate', date)
                                    }}
                                    disabledDate={/*job ? disabledTo(job.endTime, true) :*/ undefined}
                                />
                            </div>
                        </div>
                        <Field name="note"
                            render={({ field, form }: FieldProps<FormValues>) => (
                                <div className={`form-group`}>
                                    <label htmlFor="injury-pending-note">Note</label>
                                    <textarea className="form-control"
                                        id="injury-pending-note" {...field}></textarea>
                                </div>
                            )}
                        />
                    </div>
                    <div className="text-right">
                        <Button isLoading={this.state.isLoading} onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            formikBag.handleSubmit()
                        }}>Save</Button>
                    </div>
                </div>
            }
            }
        />
    }
}

export default connect<ReduxProps, {}, OwnProps>((state: ApplicationState) => ({
    injuryPendingReasons: state.injuryPendingReasons
}),
    (dispatch) => ({
        pendingReasonsActions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
    })
)(PendingForm as any)