import * as React from "react";

import * as Yup from 'yup';

import {request} from "@common/react/components/Api";
import * as moment from "moment";

import Modal from "antd/lib/modal";

import message from "antd/lib/message";

import {default as Datepicker, disabledFrom, disabledTo} from "@common/react/components/Forms/Datepicker/Datepicker";
import {ItemsState} from "@common/react/store/ItemList";
import {Company} from "@app/objects/Company";
import {Injury} from "@app/objects/Injury";
import Event from "@app/objects/Event";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getEventActionCreators} from "@app/store/Event";
import {Field, FieldProps, Form, Formik, FormikProps, FieldArray} from "formik";
import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";
import ScrollToError from "@common/react/components/Forms/ScrollToError";
import {DayOfWeek, WeekDay} from "@app/objects/Schedule";

import { Loading } from "@app/components/UI/Loading";
import Checkbox  from 'antd/lib/checkbox';
import {dateFormat} from "@common/typescript/Utils";

import Tabs from 'antd/lib/tabs';
import {ApplicationState} from "@app/store";
import { fail } from "assert";

const TabPane = Tabs.TabPane;

interface AddEventPopupOwnProps {
	companies?: ItemsState<Company>;
	companyId: number | null | undefined;
	injury: Injury;
	onSave: () => void;
	event?: any;
	onCancel: () => void
}

interface AddEventPopupState {
	visible: boolean;
	checkedDay: number | null;
	checked: boolean;
    activeKey: string;
    isLoading: boolean;
}

interface CustomEventFormValues {
	startTime: string | null;
	endTime: string | null;
	lateInAlert: number;
	Date: number ;
	lateOutAlert: number;
}

interface WorkDay {
	name: string;
	checked: boolean;
	startTime: string | null | number;
	endTime: string | null |  number;
	lateInAlert: string | null;
	lateOutAlert: string | null;
	day: number;
	replicateToAll: false
}

type ValidateThis = {
	path: any;
	schema: any;
	options: any;
	parent: any;
	createError: any;
}

const validationSchema = Yup.object().shape({
	isEdit: Yup.bool(),
	startTime: Yup.number().required(),
	endTime: Yup.number().required(),
	workDays: Yup.array().of(Yup.object().shape({
		checked: Yup.bool(),
		replicateToAll: Yup.bool(),
		startTime: Yup.mixed().test('time', 'Required field!', function test(this: ValidateThis, value) {
			const parent: WorkDay = this.parent;
			return (parent.replicateToAll && parent.day !== DayOfWeek.Monday) || !parent.checked ? true : value !== null;
		}),
		endTime: Yup.mixed().test('time', 'Required field!', function test(this: ValidateThis, value) {
			const parent: WorkDay = this.parent;
			return (parent.replicateToAll && parent.day !== DayOfWeek.Monday) || !parent.checked ? true : value !== null;
		})
	})),
	changeFrom: Yup.number().nullable().when('isEdit', { is: true, then: Yup.number().required() })
});

const customEventValidationSchema = Yup.object().shape({
	startTime: Yup.mixed().required(),
	endTime: Yup.mixed().required()
});

const getTimePickerPopup = triggerNode => document.body;

const timepickerProps = {
	use12Hours: true,
	format: "h:mm a",
	getPopupContainer: getTimePickerPopup
};

interface FormValues {
	note: string;
	companyId: number | null;
	startTime: number | null;
	endTime: number | null;
	lunchStartTime: number | null;
	lunchEndTime: number | null;
	workDays: any[];
	lateInAlert: number;
	lateOutAlert: number;
	changeFrom: number | null;
	isEdit: boolean;
}


interface Actions {
	actions: ActionCreatorsMapObject
}

type AddEventPopupProps = AddEventPopupOwnProps & Actions;

const checkWorkDaysErrors = (prop: string, index: number, formikBag: FormikProps<FormValues>) => {
	const errors = formikBag.errors.workDays,
		touches = formikBag.touched;
	
	return errors && touches && errors[index] && touches[index] &&
	(errors[index] as any)[prop] && (touches[index] as any)[prop] ? 'has-error' : '';
};


class AddEventPopup extends React.Component<AddEventPopupProps, AddEventPopupState> {
	form: any;
	customForm: any;

	days: WorkDay[];
	
	constructor(props: AddEventPopupProps) {
		super(props);
		
		const startTime = '06:00:00',
			endTime = '12:00:00';
		
		this.days = [
			{ name: 'Monday', day: DayOfWeek.Monday, checked: false, startTime:  startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Tuesday', day: DayOfWeek.Tuesday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Wednesday', day: DayOfWeek.Wednesday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Thursday', day: DayOfWeek.Thursday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Friday', day: DayOfWeek.Friday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Saturday', day: DayOfWeek.Saturday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false },
			{ name: 'Sunday', day: DayOfWeek.Sunday, checked: false, startTime: startTime, endTime: endTime, lateInAlert: '', lateOutAlert: '', replicateToAll: false }
		];
		
		if(props.event && props.event.schedule && props.event.schedule.workDays) {
            const workDays = props.event.schedule.workDays.list;
            //console.log(props);
			workDays.forEach((item: WeekDay) => {
				const index = this.days.findIndex((day) => {
					return item.day === day.day;
				});
				
				if(index !== -1) {
					const day = this.days[index];
					
					day.checked = true;
					day.startTime = (item.startTime as string);
					day.endTime = (item.endTime as string);
					day.lateInAlert = item.lateInAlert ? ('' + item.lateInAlert) : '';
					day.lateOutAlert = item.lateOutAlert ? ('' + item.lateOutAlert) : '';
				}
			});
		}
		
		this.state = {
			visible: true,
			checked: false,
			checkedDay: null,
            activeKey: "schedule",
            isLoading: false
		};
		
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.toggleReplicateAll = this.toggleReplicateAll.bind(this);
		this.submitDayForm = this.submitDayForm.bind(this);
	}

    handleSubmitForm(values: FormValues, { resetForm }) {
        if (this.state.isLoading) return;
        this.setState({ isLoading: true });
        let workDays: WorkDay[] = values.workDays.filter(day => day.checked);
        
		if (workDays.length !== 0) {
			
			const event = this.props.event,
				offset = moment().utcOffset();

            if (this.state.checked && workDays.length > 1) {
                var dayIndex = (this.state.checkedDay != null && this.state.checkedDay < 6) ? this.state.checkedDay+1 : 0;
                var repdays = values.workDays.filter(day => day.day == dayIndex);
                if (repdays.length > 0) {
                    const firstDay = repdays[0];
                    //workDays[this.state.checkedDay != null ? this.state.checkedDay : 0];
                    workDays = workDays.map((day: WorkDay, index: number) => {
                        //if(index > 0) {
                        return {
                            ...day,
                            startTime: firstDay.startTime,
                            endTime: firstDay.endTime,
                            lateInAlert: firstDay.lateInAlert,
                            lateOutAlert: firstDay.lateOutAlert
                        }
                        //}
                        //return day;

                    });
                    
                }
            }

            let data: any = {
				companyId: values.companyId,
				injuryId: this.props.injury.id,
				startTime: (!event || !event.schedule || event.schedule.startTime !== +(values.startTime as number)) ? +moment(+(values.startTime as number)).startOf('day').add(offset, 'minutes') : event.schedule.startTime,
				endTime: (!event || !event.schedule ||  event.schedule.endTime !== +(values.endTime as number)) ? +moment(+(values.endTime as number)).startOf('day').add(offset, 'minutes') : event.schedule.endTime,
				note: values.note,
				lunchStartTime: values.lunchStartTime,
				lunchEndTime: values.lunchEndTime,
				workDays: workDays,
				changeFrom: values.changeFrom
            };
            if (event) {
                data = {
                    ...event.schedule,
                    companyId: values.companyId,
                    injuryId: this.props.injury.id,
                    startTime: (!event || !event.schedule || event.schedule.startTime !== +(values.startTime as number)) ? +moment(+(values.startTime as number)).startOf('day').add(offset, 'minutes') : event.schedule.startTime,
                    endTime: (!event || !event.schedule || event.schedule.endTime !== +(values.endTime as number)) ? +moment(+(values.endTime as number)).startOf('day').add(offset, 'minutes') : event.schedule.endTime,
                    note: values.note,
                    lunchStartTime: values.lunchStartTime,
                    lunchEndTime: values.lunchEndTime,
                    workDays: workDays,
                    changeFrom: values.changeFrom
                };
            }
			
			if(event && event.schedule) {
				data.id = event.schedule.id;
			}
			
			request('schedule', data).then((result) => {
				//this.props.actions.addItem('events', result);
				
				resetForm();
				
				this.setState({
                    visible: false,
                    isLoading: false
				});

				message.success('Event was successfully added');
				
				this.props.onSave();
            }).catch((error: string) => {
                this.setState({ isLoading: false });
				message.error(error);
			})
		} else {
			message.error('You should choose Work Days')
		}
	}
	
	
	toggleReplicateAll(e, formikBag, index) {
		const checked = (e.target as any).checked;
		
		const values = formikBag.values.workDays.map((item: any) => ({
				...item,
            replicateToAll: checked
		}));
		
		formikBag.setFieldValue('workDays', values);
		
		this.setState({
            checkedDay: index,
			checked: !this.state.checked
		});
	}

	submitDayForm(values: CustomEventFormValues, {resetForm}) {
		const event = this.props.event;
		
		if (event) {
            const startTime = moment(values.Date),
                endTime = moment(values.Date),
				offset = moment().utcOffset();

			startTime.hour(+(values.startTime as string).toString().split(':')[0]);
			startTime.minute(+(values.startTime as string).toString().split(':')[1]);
			endTime.hour(+(values.endTime as string).toString().split(':')[0]);
			endTime.minute(+(values.endTime as string).toString().split(':')[1]);
			
			const data = {
				...this.props.event,
				...values,
				startTime:  +startTime.add(offset, 'minutes'),
				endTime: +endTime.add(offset, 'minutes'),
				company: null,
				schedule: null,
				manual: true
            };
            if (!data.company) {
                data.injuryId = this.props.injury.id;
                data.companyId = this.props.companyId;
                data.scheduleId = this.props.event.schedule.id;
            }
	
			request('event', data).then((result) => {
				//this.props.actions.addItem('events', result);

				resetForm();
	
				this.setState({
					visible: false
				});
	
				message.success('Event was successfully added');
				
				this.props.onSave();
			}).catch((error: string) => {
				message.error(error);
			})
		}
	}

	render() {
		const companies = this.props.companies,
			event: Event | undefined = this.props.event;
		
        const active = this.state.activeKey;
        
		return (<React.Fragment>
			<Modal
                title={`Schedule for ${this.props.injury.vccCase}`}
                visible={true}
                onOk={() => active === "schedule" ? this.form.submitForm() : this.customForm.submitForm()}
                onCancel={() => { if (this.state.isLoading) return; this.props.onCancel(); }}>
                {this.state.isLoading && < Loading caption="PLEASE WAIT-PROCESSING" />}
                {!this.state.isLoading && <Tabs className="add-event-tabs" onChange={(activeKey) => this.setState({ activeKey: activeKey })}>
                    <TabPane tab="Schedule Editor" key="schedule">

                        <Formik
                            ref={node => (this.form = node)}
                            initialValues={{
                                isEdit: (event && event.id > 0) as boolean,
                                companyId: this.props.companyId || -1,
                                startTime: event && event.schedule ? event.schedule.startTime : null,
                                endTime: event && event.schedule ? event.schedule.endTime : null,
                                note: event && event.schedule ? event.schedule.note : '',
                                lunchStartTime: event && event.schedule ? event.schedule.lunchStartTime : null,
                                lunchEndTime: event && event.schedule ? event.schedule.lunchEndTime : null,
                                workDays: this.days,
                                changeFrom: event && event.schedule ? event.schedule.startTime : null
                            } as FormValues}
                            onSubmit={this.handleSubmitForm}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            render={(formikBag: FormikProps<FormValues>) => {
                                const replicateToAll = this.state.checked;
                                const formId = event ? 'event-form-' + event.id : 'new-event-form';

                                const getFormContainer = (triggerNode: Element) => document.getElementById(formId) || document.body;

                                return <Form id={formId} className="add-event-popup">
                                    <ScrollToError {...formikBag} formId={formId} />
                                    {!event && companies && <div className="row">
                                        <Field
                                            name="companyId"
                                            render={({ field, form }: FieldProps<FormValues>) => (
                                                <div className="form-group col-sm-8">
                                                    <div className="is-relative">
                                                        <select className="form-control" value={this.props.companyId} {...field}>
                                                            {companies.items.map(company => <option
                                                                value={company.id} key={company.id}>{company.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>}
                                    {event && event.schedule && <React.Fragment><label>Start date:</label> {dateFormat(event.schedule.startTime)}</React.Fragment>}

                                    <div className="row">
                                        {!event ? <div className={`form-group col-sm-6 ${formikBag.errors.startTime && formikBag.touched.startTime ? 'has-error' : ''}`}>
                                            <label>Start Date</label>
                                            <Datepicker
                                                value={formikBag.values.startTime}
                                                format="MM/DD/YY"
                                                onChange={(date) => {
                                                    formikBag.setFieldValue('startTime', date);
                                                }}
                                                antdProps={{
                                                    getCalendarContainer: getFormContainer
                                                }}
                                                disabledDate={formikBag.values.endTime ? disabledTo(formikBag.values.endTime) : undefined}
                                            />
                                        </div>
                                            : <div className={`form-group col-sm-6 ${formikBag.errors.changeFrom && formikBag.touched.changeFrom ? 'has-error' : ''}`}>
                                                <label>Change from:</label>
                                                <Datepicker
                                                    value={formikBag.values.changeFrom}
                                                    format="MM/DD/YY"
                                                    onChange={(date) => {
                                                        formikBag.setFieldValue('changeFrom', date);
                                                    }}
                                                    antdProps={{
                                                        getCalendarContainer: getFormContainer
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div className={`form-group col-sm-6 ${formikBag.errors.endTime && formikBag.touched.endTime ? 'has-error' : ''}`}>
                                            <label>End Date</label>
                                            <Datepicker
                                                value={formikBag.values.endTime}
                                                format="MM/DD/YY"
                                                onChange={(date) => {
                                                    formikBag.setFieldValue('endTime', date);
                                                }}
                                                antdProps={{
                                                    getCalendarContainer: getFormContainer
                                                }}
                                                disabledDate={formikBag.values.startTime ? disabledFrom(formikBag.values.startTime) : undefined}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <FieldArray
                                            name="workDays"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    <label>Work Days</label>
                                                    {formikBag.values.workDays.map((day, index) => (
                                                        <div key={index} className="mb10">
                                                            <div className="row">
                                                                <Field
                                                                    name={`workDays.${index}.checked`}
                                                                    children={({ field, form }: FieldProps<FormValues>) => (
                                                                        <React.Fragment>
                                                                            <div className="col-sm-6">
                                                                                <input id={`checked-${index}-${event ? event.id : 'new'}`}
                                                                                    className="toggle-checkbox"
                                                                                    type="checkbox"
                                                                                    value={field.value}
                                                                                    disabled={index === this.state.checkedDay && replicateToAll}
                                                                                    checked={field.value} {...field} />
                                                                                <label htmlFor={`checked-${index}-${event ? event.id : 'new'}`}
                                                                                    className="toggle-checkbox__label toggle-checkbox__label__sm">
                                                                                    <span
                                                                                        className="toggle-checkbox__off">OFF</span>
                                                                                    <span
                                                                                        className="toggle-checkbox__on">ON</span>
                                                                                </label>
                                                                                <label
                                                                                    htmlFor={`day.${index}`}>{day.name}</label>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}
                                                                />
                                                                {formikBag.values.workDays[index].checked && (!replicateToAll || replicateToAll && index === this.state.checkedDay) &&
                                                                    <React.Fragment>
                                                                        <div className="col-sm-6">
                                                                            <Field
                                                                                name={`workDays.${index}.replicateToAll`}
                                                                                children={({ field, form }: FieldProps<FormValues>) => (
                                                                                    <Checkbox checked={index === this.state.checkedDay ? replicateToAll : false} onChange={(e) => this.toggleReplicateAll(e, formikBag, index)}>Replicate to all</Checkbox>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                            {formikBag.values.workDays[index].checked && (!replicateToAll || replicateToAll && index === this.state.checkedDay) &&
                                                                <div className="add-event-popup__time-select">
                                                                    <div className="row">
                                                                        <div
                                                                            className={`form-group col-sm-6 ${checkWorkDaysErrors('startTime', index, formikBag)}`}>
                                                                            <label>Start Time</label>
                                                                            <Field
                                                                                name={`workDays.${index}.startTime`}
                                                                                render={({ field, form }: FieldProps<FormValues>) => (
                                                                                    <Timepicker
                                                                                        antdProps={timepickerProps}
                                                                                        value={field.value}
                                                                                        onChange={(time) => {
                                                                                            formikBag.setFieldValue(field.name, time);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className={`form-group col-sm-6 ${checkWorkDaysErrors('endTime', index, formikBag)}`}>
                                                                            <label>End Time</label>
                                                                            <Field
                                                                                name={`workDays.${index}.endTime`}
                                                                                render={({ field, form }: FieldProps<FormValues>) => (
                                                                                    <Timepicker
                                                                                        antdProps={timepickerProps}
                                                                                        value={field.value}
                                                                                        onChange={(time) => {
                                                                                            formikBag.setFieldValue(field.name, time);
                                                                                        }} />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <Field
                                                                            name={`workDays.${index}.lateInAlert`}
                                                                            render={({ field, form }: FieldProps<FormValues>) => (
                                                                                <div
                                                                                    className={`form-group col-sm-6`}>
                                                                                    <label htmlFor="lateInAlert">Late In
																				Alert</label>
                                                                                    <input className="form-control"
                                                                                        type="text" list="minutes"
                                                                                        id="lateInAlert"
                                                                                        name="lateInAlert"
                                                                                        placeholder={`Select or set own`}
                                                                                        value={day.lateInAlert || 0}
                                                                                        onChange={time => formikBag.setFieldValue(field.name, +time)} {...field} />
                                                                                    <datalist id="minutes">
                                                                                        <option value={5}>minutes</option>
                                                                                        <option value={10}>minutes</option>
                                                                                        <option value={30}>minutes</option>
                                                                                    </datalist>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                        <Field
                                                                            name={`workDays.${index}.lateOutAlert`}
                                                                            render={({ field, form }: FieldProps<FormValues>) => (
                                                                                <div
                                                                                    className={`form-group col-sm-6`}>
                                                                                    <label htmlFor="lateOutAlert">Late
																				Out Alert</label>
                                                                                    <input className="form-control"
                                                                                        type="text" list="minutes"
                                                                                        id="lateOutAlert"
                                                                                        name="lateOutAlert"
                                                                                        placeholder={`Select or set own`}
                                                                                        value={day.lateOutAlert || 0}
                                                                                        onChange={time => formikBag.setFieldValue(field.name, +time)} {...field} />
                                                                                    <datalist id="minutes">
                                                                                        <option value={5}>minutes</option>
                                                                                        <option value={10}>minutes</option>
                                                                                        <option value={30}>minutes</option>
                                                                                    </datalist>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className={`form-group col-sm-6`}>
                                            <div>
                                                <label>Lunch Start Time</label>
                                                <Field
                                                    name="lunchStartTime"
                                                    render={({ field, form }: FieldProps<FormValues>) => (
                                                        <Timepicker antdProps={timepickerProps} value={field.value}
                                                            onChange={(time) => {
                                                                formikBag.setFieldValue(field.name, time);
                                                            }} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group col-sm-6`}>
                                            <div>
                                                <label>Lunch End Time</label>
                                                <Field
                                                    name="lunchEndTime"
                                                    render={({ field, form }: FieldProps<FormValues>) => (
                                                        <Timepicker antdProps={timepickerProps} value={field.value}
                                                            onChange={(time) => {
                                                                formikBag.setFieldValue(field.name, time);
                                                            }} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Field
                                            name="note"
                                            render={({ field, form }: FieldProps<FormValues>) => (
                                                <div className={`form-group col-sm-12`}>
                                                    <label htmlFor="note">Note</label>
                                                    <div className="is-relative">
                                                        <textarea id="note" className="form-control"
                                                            value={formikBag.values.note}
                                                            onChange={note => formikBag.setFieldValue('note', note)} {...field} />
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </Form>
                            }}
                        />
                    </TabPane>
                    {event && <TabPane tab="Day Editor" key="day">
                        <React.Fragment>
                            <Formik
                                initialValues={{
                                    startTime: event.startTime > 0 ? moment.utc(event.startTime).format('HH:mm:ss') : null,
                                    endTime: event.endTime > 0 ? moment.utc(event.endTime).format('HH:mm:ss') : null,
                                    Date: event.startTime
                                } as CustomEventFormValues}
                                onSubmit={this.submitDayForm}
                                ref={node => (this.customForm = node)}
                                validationSchema={customEventValidationSchema}
                                enableReinitialize={true}
                                render={(formikBag: FormikProps<CustomEventFormValues>) => {
                                    return <Form>
                                        <div className="add-event-popup__time-select">

                                            <div className="row">
                                                <div className={`form-group col-sm-12 ${formikBag.errors.endTime && formikBag.touched.endTime ? 'has-error' : ''}`}>
                                                <label>Date</label>
                                                    <Datepicker
                                                        value={formikBag.values.Date}
                                                    format="MM/DD/YY"
                                                    onChange={(date) => {
                                                        formikBag.setFieldValue('Date', date);
                                                    }}
                                                />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div
                                                    className={`form-group col-sm-6 ${formikBag.errors.startTime && formikBag.touched.startTime ? 'has-error' : ''}`}>
                                                    <label>Start Time</label>
                                                    <Field
                                                        name="startTime"
                                                        render={({ field, form }: FieldProps<CustomEventFormValues>) => (
                                                            <Timepicker
                                                                antdProps={timepickerProps}
                                                                value={field.value}
                                                                onChange={(time) => {
                                                                    formikBag.setFieldValue(field.name, time);
                                                                }} />
                                                        )}
                                                    />
                                                </div>
                                                <div
                                                    className={`form-group col-sm-6 ${formikBag.errors.endTime && formikBag.touched.endTime ? 'has-error' : ''}`}>
                                                    <label>End Time</label>
                                                    <Field
                                                        name="endTime"
                                                        render={({ field, form }: FieldProps<CustomEventFormValues>) => (
                                                            <Timepicker
                                                                antdProps={timepickerProps}
                                                                value={field.value}
                                                                onChange={(time) => {
                                                                    formikBag.setFieldValue(field.name, time);
                                                                }} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Field
                                                    name="lateInAlert"
                                                    render={({ field, form }: FieldProps<CustomEventFormValues>) => (
                                                        <div
                                                            className={`form-group col-sm-6`}>
                                                            <label htmlFor="lateInAlert">Late In
																Alert</label>
                                                            <input className="form-control"
                                                                type="text" list="minutes"
                                                                id="lateInAlert"
                                                                name="lateInAlert"
                                                                placeholder={`Select or set own`}
                                                                value={event.lateInAlert || 0}
                                                                onChange={time => formikBag.setFieldValue(field.name, +time)} {...field} />
                                                            <datalist id="minutes">
                                                                <option value={5}>minutes</option>
                                                                <option value={10}>minutes</option>
                                                                <option value={30}>minutes</option>
                                                            </datalist>
                                                        </div>
                                                    )}
                                                />
                                                <Field
                                                    name="lateOutAlert"
                                                    render={({ field, form }: FieldProps<CustomEventFormValues>) => (
                                                        <div
                                                            className={`form-group col-sm-6`}>
                                                            <label htmlFor="lateOutAlert">Late
																Out Alert</label>
                                                            <input className="form-control"
                                                                type="text" list="minutes"
                                                                id="lateOutAlert"
                                                                name="lateOutAlert"
                                                                placeholder={`Select or set own`}
                                                                value={event.lateOutAlert || 0}
                                                                onChange={time => formikBag.setFieldValue(field.name, +time)} {...field} />
                                                            <datalist id="minutes">
                                                                <option value={5}>minutes</option>
                                                                <option value={10}>minutes</option>
                                                                <option value={30}>minutes</option>
                                                            </datalist>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                }}
                            />
                        </React.Fragment>
                    </TabPane>}
                </Tabs>}
			</Modal>
		</React.Fragment>)
	}
}

export default connect<any, Actions, AddEventPopupOwnProps>(
	(state: ApplicationState) => {},
	(dispatch, ownProps?: AddEventPopupOwnProps) => {
		return {
			actions: bindActionCreators(getEventActionCreators(ownProps!.injury!.id), dispatch)
		}
	}
)(AddEventPopup);