import * as React from 'react';
import * as moment from 'moment';

import {request} from '@app/components/Api';

import {getCalendarContainer, getExportUrl, minutesToHours} from "@app/components/Utils/Utils";
import {Injury, InjuryTime} from "@app/objects/Injury";
import {Call, CallFile, CallType, WeekDayCalls} from "@app/objects/Call";
import {List} from "@common/typescript/objects/List";
import Datepicker, {disabledTo} from "@common/react/components/Forms/Datepicker/Datepicker";
import {CallsTable} from "@app/components/Pages/Admin/Calls/CallsTable";
import Button from "@common/react/components/Forms/Button";
import CallPopupEditor from "@app/components/Pages/Admin/Calls/CallPopup";
import Modal from "antd/lib/modal";
import {User, UserRole} from "@app/objects/User";
import {BaseParams} from "@common/typescript/objects/BaseParams";
import { Employee } from '@app/objects/Employee';


interface CallsProps {
	injury: Injury;
    user: User;
    employee: Employee;
}

interface CallsState {
	isLoading: boolean;
	injuryTimes: InjuryTime | null;
	calls: List<WeekDayCalls> | null;
	visible: boolean;
	ids: number[];
	params: {
		from: number | null;
		to: number | null;
		column: BaseParams[]
	};
}

const disabledFromCustom =  (from: number | null) => (currentDate?: moment.Moment) => (
	currentDate ?  (from !== null ? currentDate.isBefore(moment(from), 'day') : false) : false
);


export default class Calls extends React.Component<CallsProps, CallsState> {
	count: number = 25;
	
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			calls: null,
			injuryTimes: null,
			visible: false,
			ids: [],
			params: {
				from: null,
				to: null,
				column: []
			}
		};

		this.loadInjuryTime = this.loadInjuryTime.bind(this);
		this.loadCalls = this.loadCalls.bind(this);
		this.loadMoreCalls = this.loadMoreCalls.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onTableVoicePlay = this.onTableVoicePlay.bind(this);
		this.onTableSort = this.onTableSort.bind(this);
		this.getUrl = this.getUrl.bind(this);
	}

	loadInjuryTime() {
		this.setState({ isLoading: true });

		request('GetInjuryTime', {
            id: this.props.injury.id,
            from: new Date(this.state.params.from != null ? this.state.params.from : 0).setHours(0, new Date().getTimezoneOffset() * -1, 0, 0),
            to: new Date(this.state.params.to != null ? this.state.params.to : 0).setHours(24, new Date().getTimezoneOffset() * -1, 0, 0),

		}).then((result: any) => {
			this.setState({
				isLoading: false,
				injuryTimes: result
			});
		});
	}
	

	loadCalls(offset: number = 0) {
		this.setState({ isLoading: true });

		request<List<WeekDayCalls>>('weekDayCallsList', {
			objectId: this.props.injury.id,
			offset: offset,
			count: this.count,
            withFiles: true,
            from: new Date(this.state.params.from != null?this.state.params.from:0).setHours(0, new Date().getTimezoneOffset() * -1, 0, 0),
            to: new Date(this.state.params.to != null ? this.state.params.to:0).setHours(24, new Date().getTimezoneOffset() * -1, 0, 0),
            column: this.state.params.column
		}).then((result: List<WeekDayCalls>) => {
			if(offset === 0) {
				this.setState({
					isLoading: false,
					calls: result
				});
			} else {
				if(this.state.calls) {
					this.setState({
						isLoading: false,
						calls: {
							...this.state.calls,
							offset: result.offset,
							list: [...this.state.calls.list, ...result.list],
						}
					});
				}
			}
		});
	}

	onTableVoicePlay(message: CallFile, date: number) {
		request('readCall', message.objectId).then(() => {
			if(this.state.calls) {
				const weekDay = this.state.calls.list.find((item: WeekDayCalls) => item.date === date);

				if(weekDay) {
					const newWeekDay: WeekDayCalls = {...weekDay};

					newWeekDay.calls = newWeekDay.calls.map((call: Call) => {
						if(call.callType === CallType.Record && call.id === message.objectId) {
							return {...call, read: true}
						}

						return call;
					});
					
					this.setState({
						calls: {
							...this.state.calls,
							list: this.state.calls.list.map((item: WeekDayCalls) => item.date === date ? newWeekDay : item),
						}
					});
				}
			}
		});
	}

	onTableSort(sortParams: BaseParams[]) {
		this.setState({
			params: {
				...this.state.params,
				column: sortParams
			}
		});
		setTimeout( () => { this.loadCalls() }, 10);
	}

	closeDialog() {
		this.setState({
			visible: false
		})
	}

	onEdit(ids: number[]) {
		this.setState({
			ids: ids, 
			visible: true
		});
	}
	
	loadMoreCalls() {
		if(this.state.calls)
			this.loadCalls(this.state.calls.offset + this.count);
	}
	
	componentDidMount() {
		this.loadInjuryTime();
		this.loadCalls();
	}

	applyFilter(filter: string, value: number | null) {
		this.setState({
			...this.state,
			params: {
				...this.state.params,
				[filter]: value
			}
		});
		
        setTimeout(() => { this.loadCalls(); this.loadInjuryTime(); }, 10);
	}
	
	getUrl(url: string) {
		url += `?vccCase=${this.props.injury.vccCase}&timeFormat=12`;
		const params = this.state.params;
		
		if (params.from) {
			url += `&from=${moment(params.from).format().split('+')[0]}`; 
		}
        if (params.to) {
            console.log(params.to)
            url += `&to=${moment(new Date(params.to).setHours(24, 0, 0, 0)).format().split('+')[0]}`;
        }
        if (true) {
            url += '&offset=' + ((new Date().getTimezoneOffset())*-1);
        }
		
		return url;
	}
	
	render() {
		const {injuryTimes, calls, isLoading, ids, visible, params} = this.state;

        const { user, injury, employee } = this.props;
		
		const isNotExternal = user && user.role !== UserRole.External;
		
		return (
			<div className="injury-calls">
				<div className="row">
					<div className="col-sm-7">
						<div className="clearfix">
							<div className="pull-left" style={{marginRight: '10px', width: '40%'}}>
								<Datepicker
									disabledDate={disabledTo(params.to)}
									value={params.from}
									format="MM/DD/YY"
									onChange={date => this.applyFilter('from', date ? new Date(date).setHours(0,0,0,0) : date)}
									antdProps={{
										placeholder: "From",
										getCalendarContainer: getCalendarContainer('.content-inner')
									}}
								/>
							</div>
							<div className="pull-left" style={{marginRight: '10px', width: '40%'}}>
								<Datepicker
									disabledDate={disabledFromCustom(params.from)}
									value={params.to}
									format="MM/DD/YY"
									onChange={date => this.applyFilter('to', date ? new Date(date).setHours(24,0,0,-1) : date)}
									antdProps={{
										placeholder: "To",
										getCalendarContainer: getCalendarContainer('.content-inner')
									}}
								/>
							</div>
							{isNotExternal && <div className="pull-left">
								<button className="btn btn-primary" type="button" onClick={e => this.onEdit([-1])}>Add Call</button>
							</div>}
						</div>
					</div>
					<div className="pull-left">
						{injuryTimes && <div className="pull-left injury-calls__total total-hours">Total Hours: {minutesToHours(injuryTimes.time)}</div>}
					</div>
					<div className="pull-right" style={{paddingRight: 20}}>
						<a className="btn btn-success" target="_blank" title="Export to Excel" href={this.getUrl('/getTimeCards')}><i className="fa fa-file-excel-o"/></a>
						<a className="btn btn-pdf" href={this.getUrl('/getTimeCardsPdf')} target="_blank" title="Export to pdf"><i className="fa fa-file-pdf-o"/></a>
					</div>
				</div>
				{calls && calls.list.length > 0
					? <React.Fragment>
						<CallsTable 
							calls={calls.list} 
							timeFormat={12} 
							onEdit={isNotExternal ? this.onEdit : undefined}
							onVoicePlay={this.onTableVoicePlay}
							onSort={this.onTableSort}
							onRefreshCalls={this.loadCalls}
							isNotExternal={isNotExternal}
						/>
						<div className="text-center hide-print">
							{calls.count - calls.offset > this.count && <Button isLoading={isLoading} type="button" className="btn btn-primary" onClick={this.loadMoreCalls}>Load More</Button>}
						</div>
					</React.Fragment>
					: <div className="not-found-title">Calls not found</div>
				}
				<Modal 
					title={ids && ids[0] > 0 ? 'Edit Call' : 'Add New Call'}
					visible={visible}
					onCancel={this.closeDialog}
					width={600}
					footer={null}
					destroyOnClose={true}>
                    <CallPopupEditor onClose={this.closeDialog} ids={ids} injury={injury} employee={employee}/>
				</Modal>
			</div>
		);
	}
}