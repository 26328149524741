import * as React from 'react';
import Alert, {EventStatus} from "@app/objects/alert";
import {Link} from "react-router-dom";
import {dateFormat, getUserName} from "@app/components/Utils/Utils";
import {SpecialCompanyType} from "@app/objects/CompanyType";
import {DayOfWeek} from "@app/objects/Schedule";
import {stateZoneNames} from "@common/react/components/Forms/StateTimeZoneSelect/StateTimeZoneSelect";
import {Company} from "@app/objects/Company";
import {Loading} from "@app/components/UI/Loading";
import {request} from "@app/components/Api";

interface ComponentProps {
	id: number
}

interface ComponentState {
	item: Company | null | undefined;
}

export class CompanyView extends React.Component<ComponentProps, ComponentState> {
	constructor(props) {
		super(props);
		
		this.state = {
			item: null
		}
	}
	
	componentWillMount(): void {
		request('getCompany', { id: this.props.id, withPhones: true }).then(res => {
			 this.setState({ item: res as Company })
		});
	}

	render() {
		const item = this.state.item;
		
		if (item) {
			return <React.Fragment>
				<div className="row">
					<div className="form-group col-sm-6">
						<label>Company Name</label>
						<br/>
						{item.name ? item.name : '-'}
					</div>
					<div className="form-group col-sm-6">
						<label>Company Type</label>
						<br/>
						{item.companyTypeId ? SpecialCompanyType[item.companyTypeId] : '-'}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-6">
						<label>Head Company</label>
						<br/>
						{item.parent ? item.parent.name : '-'}
					</div>
					<div className="form-group col-sm-6">
						<label>Referral</label>
						<br/>
						{item.referral ? item.referral.name : '-'}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label>Placement Fee</label>
						<br/>
						{item.placementFee1 ? item.placementFee1 : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>2nd Placement Fee</label>
						<br/>
						{item.placementFee2 ? item.placementFee2 : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>3rd Placement Fee</label>
						<br/>
						{item.placementFee3 ? item.placementFee3 : '-'}
					</div>
				</div>
				{item.companyTypeId === SpecialCompanyType.Employer && <div className="row">
					<div className="form-group col-sm-4">
						<label>Payroll Period</label>
						<br/>
						{item.payrollPeriod ? item.payrollPeriod : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>Employer Day of the Week Payroll Starts</label>
						<br/>
						{item.startDate ? DayOfWeek[item.startDate] : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>Start Date</label>
						<br/>
						{item.startDateTime ? dateFormat(item.startDateTime) : '-'}
					</div>
				</div>}
				<div className="row">
					<div className="form-group col-sm-6">
						<label>First Name</label>
						<br/>
						{item.firstName ? item.firstName : '-'}
					</div>
					<div className="form-group col-sm-6">
						<label>Last Name</label>
						<br/>
						{item.lastName ? item.lastName : '-'}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-6">
						<label>Address</label>
						<br/>
						{item.address ? item.address : '-'}
					</div>
					<div className="form-group col-sm-6">
						<label>Time Zone</label>
						<br/>
						{item.timeZone ? stateZoneNames[item.timeZone] : '-'}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label>City</label>
						<br/>
						{item.city ? item.city: '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>State</label>
						<br/>
						{item.state ? item.state : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>Zip</label>
						<br/>
						{item.zip ? item.zip : '-'}
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label>Business Phone</label>
						<br/>
						{item.phone ? item.phone: '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>Extension</label>
						<br/>
						{item.extension ? item.extension : '-'}
					</div>
					<div className="form-group col-sm-4">
						<label>Fax</label>
						<br/>
						{item.fax ? item.fax : '-'}
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label htmlFor="email">Email for Alerts on all Employer Cases</label>
						<br/>
						{item.email ? item.email : '-'}
					</div>
				</div>
				{item.companyTypeId != SpecialCompanyType.NonProfit
					? ''
					: <React.Fragment>
						<div className="row">
							<div className="col-sm-12">
								<label>Worksite  Description</label>
								<br/>
								{item.nonProfitDescription ? item.nonProfitDescription : '-'}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<label htmlFor={`dressCode`}>Dress Code</label>
								<br/>
								{item.dressCode ? item.dressCode : '-'}
							</div>
						</div>
					</React.Fragment>
				}
				{item.id > 0 && item.user && <React.Fragment>
					<div className="row">
						<div className="col-sm-6 form-group">
							<label>Date Created</label>
							<br/>
							{dateFormat(item.time)}
						</div>
						<div className="col-sm-6 form-group">
							<label>Added by</label>
							<br/>
							{getUserName(item.user)}
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6 form-group">
							<label>Last Modifed</label>
							<br/>
							{dateFormat(item.lastModifyTime)}
						</div>
						<div className="col-sm-6 form-group">
							<label>Last Modified by</label>
							<br/>
							{item.lastModifyUser && getUserName(item.lastModifyUser)}
						</div>
					</div>
				</React.Fragment>}
			</React.Fragment>
		}
		
		return <Loading/>;
	}
}