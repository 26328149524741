import * as React from "react";
import * as Yup from 'yup';
import FollowUp, {Recurrence} from "@app/objects/FollowUp";
import {request} from "@app/components/Api";
import message from "antd/lib/message";
import {FollowUpType} from "@app/objects/FollowUpType";
import Schedule from "@app/objects/Schedule";
import {Field, FieldProps, Formik, FormikProps} from "formik";
import ScrollToError from "@common/react/components/Forms/ScrollToError";
import {default as Datepicker, disabledTo} from "@common/react/components/Forms/Datepicker/Datepicker";
import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";
import {Injury, InjuryStatus} from "@app/objects/Injury";
import {connect} from "react-redux";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {ApplicationState} from "@app/store";
import * as ItemsState from "@common/react/store/ItemList";
import * as PagesState from "@app/store/ItemList";
import Button from "@common/react/components/Forms/Button";

import * as moment from "moment";
import {StatusHistory} from "@app/objects/StatusHistory";

interface OwnProps {
	injury: Injury;
	onSave?: () => void;
	jobs: Schedule[];
	statusHistory: StatusHistory | null;
}

interface FollowUpFormState {
	isLoading: boolean;
}

interface ReduxProps {
	followUpTypes: PagesState.ItemsState<FollowUpType>
}

type Props = OwnProps & ReduxProps & {actions: ActionCreatorsMapObject};


const validationSchema = Yup.object().shape({
	followUpTypeId: Yup.number().min(1, 'Required field!'),
	scheduleId: Yup.number().min(1, 'Required field!'),
	date: Yup.number().required(),
	recurrence: Yup.number().min(0, 'Required field!'),
});

const timepickerProps = {
	use12Hours: true,
	format: "h:mm a"
};

// export const disabledDate = (from: number, to: number) => (currentDate: moment.Moment) => currentDate && (currentDate.isAfter(moment(to), 'day') || currentDate.isBefore(moment(from), 'day'));


class FollowUpForm extends React.Component<Props, FollowUpFormState> {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false
		};

		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	handleSubmitForm(values: FollowUp, {resetForm}) {
		if(this.props.statusHistory) {
			this.setState({
				isLoading: true
			});

			const date = moment(values.date as number);

			if(values.time) {
				date.hour(+values.time.split(':')[0]);
				date.minute(+values.time.split(':')[1]);
			}
			debugger;

			const item = {...values, date: +date, time: null, statusHistoryId: this.props.statusHistory.id};

			request<FollowUp>('followUp', item).then((result: FollowUp) => {
				this.setState({
					isLoading: false
				});

				message.success('Follow-up was successfully added');

				resetForm();
				

				this.props.onSave && this.props.onSave();
			}).catch(() => {
				this.setState({
					isLoading: false
				});
			});
		}
	}
	
	componentWillMount() {
		this.props.actions.reqPages('followUpTypes', 'followUpTypeList', 'followUpType', { page: 1, text: '', count: 100 });
	}

	render() {
		const followUpTypes = this.props.followUpTypes.items;
		
		const injury = this.props.injury;
		
		const statusHistory = this.props.statusHistory;
		
		const disabled = injury.injuryStatus !== InjuryStatus.FollowUp || (statusHistory && (statusHistory.injuryStatus !== InjuryStatus.FollowUp));
		
		const jobs = this.props.jobs || [];

		return (<div className={`injury-status-form ${disabled ? 'injury-status-form__disabled' :''}`}>
			<Formik
					initialValues={{
						injuryId: this.props.injury.id,
						followUpTypeId: -1,
						scheduleId: -1,
						date: Date.now(),
						time: new Date().getHours() + ':' + new Date().getMinutes(),
						recurrence: -1
					} as FollowUp}
					onSubmit={this.handleSubmitForm}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(formikBag: FormikProps<FollowUp>) => {
						const job = jobs.find((job: Schedule) => job.id === +formikBag.values.scheduleId);
						
						return <div id={'new-follow-up-form'}>
							<ScrollToError {...formikBag} formId={'new-follow-up-form'}/>
							<div className="row">
								<Field
									name="scheduleId"
									render={({field, form}: FieldProps<FollowUp>) =>
										<div
											className={`form-group col-sm-6 ${form.errors.scheduleId ? 'has-error' : ''}`}>
											<label htmlFor="scheduleType">Job*</label>
											<div className="is-relative">
												<select className="form-control" id="scheduleType" {...field}>
													<option disabled value={-1}>Select job</option>
													{jobs.map((job: Schedule) => (
														<option value={job.id}
														        key={job.id}>{job.company ? job.company.name : ''}</option>))
													}
												</select>
											</div>
										</div>
									}
								/>
								<Field
									name="followUpTypeId"
									render={({field, form}: FieldProps<FollowUp>) =>
										<div
											className={`form-group col-sm-6 ${form.errors.followUpTypeId ? 'has-error' : ''}`}>
											<label htmlFor="followUpType">Follow-up Type*</label>
											<div className="is-relative">
												<select className="form-control" id="followUpType" {...field}>
													<option disabled value={-1}>Select follow-up type</option>
													{followUpTypes && followUpTypes.map(followUpType => (
														<option value={followUpType.id}
														        key={followUpType.id}>{followUpType.name}</option>))
													}
												</select>
											</div>
										</div>
									}
								/>
							</div>
							<div className="row">
								<div
									className={`form-group col-sm-6 ${formikBag.errors.date && formikBag.touched.date ? 'has-error' : ''}`}>
									<label>Date*</label>
									<Datepicker
										value={formikBag.values.date}
										format="MM/DD/YY"
										onChange={(date) => {
											formikBag.setFieldValue('date', date);
										}}
										disabledDate={/*job ? disabledTo(job.endTime, true) :*/ undefined}
										antdProps={{
											disabled: +formikBag.values.scheduleId === -1
										}}
									/>
								</div>
								<div
									className={`form-group col-sm-6`}>
									<div>
										<label>Time</label>
										<Field
											name="time"
											render={({field, form}: FieldProps<FollowUp>) => (
												<Timepicker antdProps={timepickerProps} value={field.value}
												            onChange={(time) => {
													            formikBag.setFieldValue(field.name, time);
												            }}/>
											)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div
									className={`form-group col-sm-6 ${formikBag.errors.recurrence && formikBag.touched.recurrence ? 'has-error' : ''}`}>
									<label>Recurrence*</label>
									<Field
										name="recurrence"
										render={({field, form}: FieldProps<FollowUp>) => (
											<div className="is-relative">
												<select className="form-control" id="recurrence"
												        value={field.value} {...field}>
													<option disabled value={-1}>Select recurrence</option>
													<option value={Recurrence.OneTime}>One Time</option>
													<option value={Recurrence.Daily}>Daily</option>
													<option value={Recurrence.Weekly}>Weekly</option>
													<option value={Recurrence.ByWeekly}>Bi-Weekly</option>
													<option value={Recurrence.Monthly}>Monthly</option>
												</select>
											</div>
										)}
									/>
								</div>
							</div>
							<div className="text-right">
								<Button isLoading={this.state.isLoading} className="btn btn-success" onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									formikBag.handleSubmit()
								}}>Add Follow-up</Button>
							</div>
						</div>
					}}
				/>
		</div>)
	}
}


export default connect<ReduxProps, {actions: ActionCreatorsMapObject}, OwnProps>(
	(state: ApplicationState) => ({followUpTypes: state.followUpTypes}),
	(dispatch) => ({
		actions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
	})
)(FollowUpForm);