import * as React from "react";
import * as Yup from 'yup';

import {Field, FieldProps, Form, Formik, FormikProps} from "formik";

import {default as Datepicker} from "@common/react/components/Forms/Datepicker/Datepicker";
import {Injury, InjuryStatus} from "@app/objects/Injury";
import {connect} from "react-redux";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {ApplicationState} from "@app/store";
import * as ItemsState from "@common/react/store/ItemList";
import * as PagesState from "@app/store/ItemList";
import Button from "@common/react/components/Forms/Button";
import {OutcomeType} from "@app/objects/OutcomeType";
import Schedule from "@app/objects/Schedule";
import {request} from "@app/components/Api";
import {StatusHistory} from "@app/objects/StatusHistory";
import {deleteConfirmation} from "@app/components/Utils/Modal";


interface OwnProps {
	injury: Injury;
	jobs: Schedule[];
	onSave: (item: StatusHistory) => void
}

interface FormState {
	isLoading: boolean;
}

interface ReduxProps {
	outcomeTypes: PagesState.ItemsState<OutcomeType>
}

type Props = OwnProps & ReduxProps & {actions: ActionCreatorsMapObject};


const validationSchema = Yup.object().shape({
	closedTime: Yup.number().required(),
	outcomeTypeId: Yup.number().min(1, 'Required field!'),
	// scheduleId: Yup.number().min(1, 'Required field!'),
});

interface FormValues {
	closedTime: number | null;
	outcomeTypeId: number;
	scheduleId: number;
	rtwDate: number | null;
	lastDayWorked: number | null;
}

class ClosedForm extends React.Component<Props, FormState> {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false
		};

		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

	handleSubmitForm(values: FormValues, {resetForm}) {
		deleteConfirmation(() => {
			let newValues = values;
			
			if(!this.isRTWFD(+values.outcomeTypeId)) {
				newValues.rtwDate = null;
				newValues.lastDayWorked = null;
			}
			
			const item = {
				id: -1,
				injuryStatus: InjuryStatus.Closed,
				injuryId: this.props.injury.id,
				...values,
				scheduleId: +values.scheduleId > 0 ? +values.scheduleId : null
			};

			this.setState({
				isLoading: true
			});

			request<StatusHistory>('statusHistory', item).then((result: StatusHistory) => {
				this.setState({
					isLoading: false
				});

				resetForm();

				if(result.injury) {
					this.props.onSave(result);
				}
			}).catch(() => {
				this.setState({
					isLoading: false
				});
			});

		}, 	' Are you sure that you want to complete this schedule')
	}
	
	componentWillMount() {
		this.props.actions.reqPages('outcomeTypes', 'outcomeTypeList', 'outcomeType', { page: 1, text: '', count: 100 });
	}

	isRTWFD (id: number) {
		const outcomeType = this.props.outcomeTypes.items.find((item: OutcomeType) => id === item.id);
		return outcomeType && outcomeType.name.replace(/\s/gi, '').toLowerCase() === 'rtwfd';
	}
	
	render() {
		const outcomeTypes = this.props.outcomeTypes.items;
		
		const jobs = this.props.jobs || [];
		
		return (
			<Formik
				initialValues={{
					closedTime: null,
					outcomeTypeId:  -1,
					scheduleId: -1,
					lastDayWorked: null,
					rtwDate: null
				}}
				onSubmit={this.handleSubmitForm}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(formikBag: FormikProps<FormValues>) => {
					const isRTWFD = this.isRTWFD(+formikBag.values.outcomeTypeId);
					return <React.Fragment>
						<div className="injury-status-form">
							<div className="row">
								<Field
									name="scheduleId"
									render={({field, form}: FieldProps<FormValues>) =>
										<div className={`form-group col-sm-6 ${form.errors.scheduleId ? 'has-error' : ''}`}>
											<label htmlFor="scheduleType">Job</label>
											<div className="is-relative">
												<select className="form-control" id="scheduleType" {...field}>
													<option disabled value={-1}>Select job</option>
													{jobs && jobs.map(job => (
														<option value={job.id} key={job.id}>{job.company ? job.company.name : ''}</option>))
													}
												</select>
											</div>
										</div>
									}
								/>
								<div className={`form-group col-sm-6 ${formikBag.errors.closedTime && formikBag.touched.closedTime ? 'has-error' : ''}`}>
									<label>Closed Date*</label>
									<Datepicker
										value={formikBag.values.closedTime}
										format="MM/DD/YY"
										onChange={(date) => {
											formikBag.setFieldValue('closedTime', date);
										}}
										minDateToday={true}
										// antdProps={{
										// 	disabled: +formikBag.values.scheduleId === -1
										// }}
									/>
								</div>
							</div>
							<div className="row">
								<div className={`form-group col-sm-6 ${formikBag.errors.outcomeTypeId && formikBag.touched.outcomeTypeId ? 'has-error' : ''}`}>
									<label>Outcome*</label>
									<Field
										name="outcomeTypeId"
										render={({field, form}: FieldProps<FormValues>) => (
											<div className="is-relative">
												<select className="form-control" id="recurrence" {...field}>
													<option disabled value={-1}>Select outcome</option>
													{outcomeTypes && outcomeTypes.map((outcome: OutcomeType) => (
														<option value={outcome.id}
														        key={outcome.id}>{outcome.name}</option>))
													}
												</select>
											</div>
										)}
									/>
								</div>
							</div>
							{isRTWFD && <div className="row">
								<div className={`form-group col-sm-6`}>
									<label>Last Day Worked</label>
									<Datepicker
										value={formikBag.values.lastDayWorked}
										format="MM/DD/YY"
										onChange={(date) => {
											formikBag.setFieldValue('lastDayWorked', date);
										}}
										antdProps={{
											disabled: +formikBag.values.scheduleId === -1
										}}
									/>
								</div>
								<div className={`form-group col-sm-6`}>
									<label>RTW Date</label>
									<Datepicker
										value={formikBag.values.rtwDate}
										format="MM/DD/YY"
										onChange={(date) => {
											formikBag.setFieldValue('rtwDate', date);
										}}
										antdProps={{
											disabled: +formikBag.values.scheduleId === -1
										}}
									/>
								</div>
							</div>}
						</div>
						<div className="text-right">
							<Button isLoading={this.state.isLoading} onClick={e => {e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit()}}>Save</Button>
						</div>
					</React.Fragment>
				}}
			/>);
	}
}


export default connect<ReduxProps, {actions: ActionCreatorsMapObject}, OwnProps>(
	(state: ApplicationState) => ({outcomeTypes: state.outcomeTypes}),
	(dispatch) => ({
		actions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
	})
)(ClosedForm);