import * as React from 'react';

import {request} from '@app/components/Api';

import {dateFormat} from "@app/components/Utils/Utils";

import Modal from "antd/lib/modal";

import {deleteConfirmation} from "@app/components/Utils/Modal";
import {renderMobileCell} from "@common/react/components/Pages/ItemsPage";
import {Injury, InjuryStatus} from "@app/objects/Injury";
import {UserRole} from "@app/objects/User";

import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";

import {ItemsState} from "@common/react/store/ItemList";

import {Correspondence} from "@app/objects/Correspondence";
import {ColumnProps} from "antd/lib/table";

import Table from "antd/lib/table";
import CorrespondenceEditor from "@app/components/Pages/Employees/Correspondence/CorrespondenceEditor";
import {getSelectedInjuryCorrespondenceActionCreators} from "@app/store/InjuryCorrespondence";


interface CallsProps {
	injury: Injury;
	userRole: number;
	isEditable: boolean;
}

interface ReduxProps {
	correspondence: ItemsState<Correspondence>,
}

type ComponentProps = CallsProps & ReduxProps & {
	injuryCorrespondenceActions: ActionCreatorsMapObject;
};

interface CallsState {
	isLoading: boolean;
	visible: boolean;
	reportId: number;
}

class CorrespondenceTab extends React.Component<ComponentProps, CallsState> {
	columns: ColumnProps<Correspondence>[];
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			visible: false,
			reportId: -1
		};

		this.columns = [
			{
				title: 'Shipping Vendor',
				dataIndex: 'shippingVendor',
				render: (text: string, record: Correspondence) => renderMobileCell('Shipping Vendor', record.vendor ? record.vendor.name  : '-')
			},
			{
				title: 'Date Sent',
				dataIndex: 'dropOffDate',
				render: (text: string, record: Correspondence) => renderMobileCell('Date Sent',  record.dropOffDate && record.dropOffDate > 0 ? dateFormat(record.dropOffDate) : '-')
			},
			{
				title: 'Tracking Number',
				dataIndex: 'trackingNumber',
				render: (text: string, record: Correspondence) => renderMobileCell('Tracking number', text)
			},
			{
				title: 'Date Received',
				dataIndex: 'deliveredTime',
				render: (text: string, record: Correspondence) => renderMobileCell('Date Received', record.deliveredTime && record.deliveredTime > 0 ? dateFormat(record.deliveredTime) : '-')
			},
			{
				title: 'Undeliverable',
				dataIndex: 'undeliverable',
				render: (text: string, record: Correspondence) => renderMobileCell('Undeliverable', record.undeliverable ? 'Yes' : 'No')
			},
			{
				title: 'Undeliverable Reason',
				dataIndex: 'undeliverableReason',
				render: (text: string, record: Correspondence) => renderMobileCell('Undeliverable Reason', record.undeliverableReason)
			},
			{
				title: 'Correspondence Type',
				dataIndex: 'correspondenceType',
				render: (text: string, record: Correspondence) => renderMobileCell('Correspondence Type', record.correspondenceType ? record.correspondenceType.name : '-')
			},
			{
				title: '',
				dataIndex: 'actions',
				render: (text, record: Correspondence) => (
					<div className="text-right table-actions">
						<button type="button" className="btn btn-sm btn-default" title="Edit" onClick={e => this.openReport(record.id)}><i className="fa fa-edit"></i></button>
						<button type = "button" className="btn btn-sm btn-danger" title="Delete" onClick={e => this.handleDelete(record)}><i className="fa fa-trash"></i></button>
					</div>
				)
			}
		];
		
		this.openReport = this.openReport.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onReportSave = this.onReportSave.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
	}

	componentWillMount() {
		this.props.injuryCorrespondenceActions.reqPages('injuryCorrespondence', 'correspondenceList', 'correspondence', {page: 1, objectId: this.props.injury.id});
	}
	
	openReport(id: number): void {
		this.setState({
			reportId: id,
			visible: true
		})
	}

	closeDialog() {
		this.setState({
			visible: false,
			reportId: -1
		})
	}

	onReportSave() {
		this.props.injuryCorrespondenceActions.refreshPages('injuryCorrespondence', 'correspondenceList', {page: 1, objectId: this.props.injury.id});
		this.closeDialog();
	}


	onCancel() {
		this.closeDialog()
	}

	handleDelete(report: Correspondence) {
		deleteConfirmation(() => {
			request('correspondence', {...report, deleted: true}).then(() => {
				this.props.injuryCorrespondenceActions.refreshPages('injuryCorrespondence', 'correspondenceList', {page: 1, objectId: this.props.injury.id});
			});
		});
	}
	
	handleTableChange(pagination) {
		if (pagination) this.props.injuryCorrespondenceActions.refreshPages('injuryCorrespondence', 'correspondenceList', {page: pagination.current, objectId: this.props.injury.id});
	}

	render() {
		const {reportId, visible} = this.state;

		const {injury, userRole, correspondence, isEditable} = this.props;
		return (
			<div className="correspondence">
				<div className="form-group text-right">
					<a className="btn btn-success" target="_blank" href={`/getCorrespondence?id=${injury && injury.id}`}><i className="fa fa-file-excel-o"/></a>
					{isEditable && userRole !== UserRole.External && 
						<button className="btn btn-primary" type="button" onClick={e => this.openReport(-1)}>Add Correspondence</button>}
				</div>
				<Table
					columns={this.columns}
					dataSource={correspondence ? correspondence.items : []}
					pagination={correspondence ? correspondence.pagination : false}
					loading={correspondence ? correspondence.isLoading : false}
					rowKey="id"
					className="small-padding-table"
					scroll={{x: true}}
					onChange={this.handleTableChange}
				/>
				<Modal
					title={reportId > 0 ? 'Edit Correspondence' : 'Add Correspondence'}
					visible={visible}
					onCancel={this.closeDialog}
					width={600}
					footer={null}
				>
					<CorrespondenceEditor injury={injury.id} itemId={reportId} onSave={this.onReportSave} onCancel={this.onCancel} editable={userRole !== UserRole.External}/>
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state: ApplicationState, ownProps?: CallsProps) => {
		if (!ownProps) { return {}; }

		return {
			correspondence: state.injuryCorrespondence[ownProps.injury.id]
		}
	},
	(dispatch, ownProps?: CallsProps) => {
		if (!ownProps) { return {}; }

		return {
			injuryCorrespondenceActions: bindActionCreators(getSelectedInjuryCorrespondenceActionCreators(ownProps.injury.id), dispatch)
		}
	}
)(CorrespondenceTab as any);