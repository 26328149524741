import * as React from "react";
import {dateFormat, phoneFormat, phoneReplace} from "@app/components/Utils/Utils";
import {Injury, InjuryStatus, InjurySubtype} from "@app/objects/Injury";
import {MedicalTree} from "@app/components/Forms/Comment/CommentTree";

import Collapse from "antd/lib/collapse";
import {Loading} from "@app/components/UI/Loading";
import {UserRole} from "@app/objects/User";

const Panel = Collapse.Panel;

interface ComponentProps {
	item: Injury,
	onSpoilerChange: (key: string | string[]) => void,
	role: number | null
}
const InjuryFormView: React.SFC<ComponentProps> = ({item, onSpoilerChange, role}) => {
	const isNotExternal: boolean = role !== null && role !== UserRole.External;
	
	const isAdmin: boolean = role !== null && role === UserRole.Admin;
	
	return <React.Fragment>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>NP Earliest Start Date</label>
				<div>
					{dateFormat(item.startDate)}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Referral Date</label>
				<div>
					{item.referralDate ? dateFormat(item.referralDate) : '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Assigned Person</label>
				<div>
					{item.assignedPerson ? `${item.assignedPerson.firstName || ''} ${item.assignedPerson.lastName} (${item.assignedPerson.email})` : '-'}
				</div>
			</div>
			{isAdmin && <div className={`form-group col-sm-6`}>
				<label>Total Hours Worked</label>
				<div>
					{(Math.floor((item.minutesWorked !== null ? item.minutesWorked : item.totalMinutesWorked) / 60) || '-')}
				</div>
			</div>}
		</div>
		{isAdmin && <div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Total Days Worked</label>
				<div>
					{((item.daysWorked !== null ? item.daysWorked : item.totalDaysWorked) || '-')}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Last Date Worked</label>
				<div>
					{item.lastDayWorked ? dateFormat(item.lastDayWorked) : '-'}
				</div>
			</div>
		</div>}
		{isNotExternal ? 
			<React.Fragment>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Injury Type</label>
						<div>
							{item.injuryType ? item.injuryType.name : '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Injury Subtype</label>
						<div>
							{InjurySubtype[item.subtype] || '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Date of Injury</label>
						<div>
							{item.date ? dateFormat(item.date) : ''}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Claim Number</label>
						<div>
							{item.claimNumber || '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Jurisdiction</label>
						<div>
							{item.stateJurisdiction || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Pre-Injury Weekly Hours Worked</label>
						<div>
							{item.preInjuryWeeklyHoursWorked || '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>AWW Rate</label>
						<div>
							{item.awwRate || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Comp Rate</label>
						<div>
							{item.compRate || '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>ALD Hourly Rate</label>
						<div>
							{item.hourRateALD || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Current Restriction Date</label>
						<div>
							{item.currentRestrictionDate ? dateFormat(item.currentRestrictionDate) : "-"}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Days/Time Worked</label>
						<div>
							{item.daysTimeWorked || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Next Dr. Appt:</label>
						<div>
							{item.nextDrAppt ? dateFormat(item.nextDrAppt) : "-"}
						</div>
					</div>
				</div>
			</React.Fragment>
			:
			<div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Injury Type</label>
						<div>
							{item.injuryType ? item.injuryType.name : '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Date of Injury</label>
						<div>
							{item.date ? dateFormat(item.date) : ''}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>Jurisdiction</label>
						<div>
							{item.stateJurisdiction || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>AWW Rate</label>
						<div>
							{item.awwRate || '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className={`form-group col-sm-6`}>
						<label>ALD Hourly Rate</label>
						<div>
							{item.hourRateALD || '-'}
						</div>
					</div>
					<div className={`form-group col-sm-6`}>
						<label>Days/Time Worked</label>
						<div>
							{item.daysTimeWorked || '-'}
						</div>
					</div>
				</div>
			</div>
		}
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Physician</label>
				<div>
					{item.byPhysician || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-4`}>
				<label>Placement Fee</label>
				<div>
					{item.placementFee1 || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-4`}>
				<label>2nd Placement Fee</label>
				<div>
					{item.placementFee2 || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-4`}>
				<label>3nd Placement Fee</label>
				<div>
					{item.placementFee3 || '-'}
				</div>
			</div>
		</div>
		{/*<div className="row">*/}
			{/*<div className={`form-group col-sm-12`}>*/}
				{/*<label>Current Medical Restrictions</label>*/}
				{/*<div>*/}
					{/*{item.currentMedicalRestrictions || '-'}*/}
				{/*</div>*/}
			{/*</div>*/}
		{/*</div>*/}
		<div className="form-group">
			<Collapse>
				<Panel header="Medical Restrictions" key="4">
					{/*<div className="editor-page__subheadline">*/}
						{/*<h3>Medical Restrictions</h3>*/}
					{/*</div>*/}
					<MedicalTree objectType="injury" readOnly={true} objectId={item.id} withoutAddButton={true}/>
				</Panel>
			</Collapse>
		</div>
		<div className="form-group">
			<Collapse key={item.id} onChange={onSpoilerChange}>
				<Panel header="Claim Adjuster" key="claim-adjuster">
					{item.claimAdjuster ? <React.Fragment>
						<div className="row">
							<div className={`form-group col-sm-6`}>
								<label>First Name</label>
								<div>
									{item.claimAdjuster.firstName || '-'}
								</div>
							</div>
							<div className={`form-group col-sm-6`}>
								<label>Last Name</label>
								<div>
									{item.claimAdjuster.lastName || '-'}
								</div>
							</div>
						</div>
						<div className="row">
							<div className={`form-group col-sm-6`}>
								<label>Phone</label>
								<div>
									{item.claimAdjuster.phone ? phoneFormat(item.claimAdjuster.phone) : "-"}
								</div>
							</div>
							<div className={`form-group col-sm-6`}>
								<label>Extension</label>
								<div>
									{item.claimAdjuster.extension || '-'}
								</div>
							</div>
						</div>
						<div className="row">
							<div className={`form-group col-sm-6`}>
								<label>Email</label>
								<div>
									{item.claimAdjuster.email || '-'}
								</div>
							</div>
							<div className={`form-group col-sm-6`}>
								<label>Insurance Co/TPA</label>
								<div>
									{item.claimAdjuster.insuranceCompany ? item.claimAdjuster.insuranceCompany.name : '-'}
								</div>
							</div>
						</div>
					</React.Fragment>
					: <Loading/>}	
				</Panel>
			</Collapse>
		</div>	
	</React.Fragment>
};


export default InjuryFormView;