import * as React from "react";
import { Employee, EmployeeFormValues } from "@app/objects/Employee";
import {dateFormat, getUserNameWithEmail, phoneFormat, phoneMask} from "@app/components/Utils/Utils";
import {GoogleMapButton} from "@app/components/UI/GoogleMapButton/GoogleMapButton";

interface ComponentProps {
	item: Employee
}

const EmployeeEditorView: React.SFC<ComponentProps> = ({item}) => {
	return <React.Fragment>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Company</label>
				<div>
					{item.companyName || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Referral Source</label>
				<div>
					{item.referral ? item.referral.name : ''}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6 col-sm-offset-6`}>
				<label>Referral Contact</label>
				<div>
					{item.referralContact ? item.referralContact.companyName : '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Payroll Period</label>
				<div>
					{item.payrollPeriod || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Start Date</label>
				<div>
					{dateFormat(item.startDate) || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Salutation</label>
				<div>
					{item.salutation || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>First Name</label>
				<div>
					{item.firstName || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Last Name</label>
				<div>
					{item.lastName || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Address</label>
				<div>
					{item.address || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-3`}>
				<label>Apartment Number(#)</label>
				<div>
					{item.apartment || '-'}
				</div>
			</div>
			<GoogleMapButton state={item.state} address={item.address} city={item.city} zip={item.zip}/>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>City</label>
				<div>
					{item.city || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>State</label>
				<div>
					{item.state || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Zip</label>
				<div>
					{item.zip || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Time Zone</label>
				<div>
					{item.timeZone || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Email</label>
				<div>
					{item.email || '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Gender</label>
				<div>
					{item.gender || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Occupation</label>
				<div>
					{/*{occupations.list.find(occupation => occupation.id === item.occupationId) || '-'}*/}
					{item.occupation ? item.occupation.name : '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Age</label>
				<div>
					{item.age || '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Phone</label>
				<div>
					{item.phone ? phoneFormat(item.phone) : '-'}
				</div>
			</div>
			<div className={`form-group col-sm-6`}>
				<label>Mobile</label>
				<div>
					{item.mobile ? phoneFormat(item.mobile) : '-'}
				</div>
			</div>
		</div>
		<div className="row">
			<div className={`form-group col-sm-6`}>
				<label>Languages</label>
				<div>
					{item.languages.list.map(language => <div>{language.language.name}</div>)}
				</div>
			</div>
		</div>
	</React.Fragment>
};


export default EmployeeEditorView;