import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '@app/store/index';

import moment from "moment";

import {dispatchToProps, ExtendableItemEditor} from "@app/components/Pages/ItemEditor";

import {Formik, FormikProps, Form, Field, FieldProps, FieldArray} from 'formik';

import Button from "@common/react/components/Forms/Button";
import { Loading } from "@app/components/UI/Loading";

import {ItemsState} from "@common/react/store/ItemList";

import {Correspondence} from "@app/objects/Correspondence";
import {ShippingVendor} from "@app/objects/ShippingVendor";
import {CorrespondenceType} from "@app/objects/CorrespondenceType";
import Datepicker from "@common/react/components/Forms/Datepicker/Datepicker";
import {request} from "@app/components/Api";
import message from "antd/lib/message";

import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";
import * as Yup from 'yup';

interface ReduxProps {
	shippingVendors: ItemsState<ShippingVendor>
	correspondenceTypes: ItemsState<CorrespondenceType>
}

interface OwnProps {
	injury: number;
	itemId: number;
	onSave: () => void;
	onCancel: () => void;
	editable: boolean;
}

const timepickerProps = {
	use12Hours: true,
	format: "h:mm a"
};

export interface CorrespondenceFormValues extends Correspondence{
	time: number | string | null;
}

const validationSchema = Yup.object().shape({
	correspondenceTypeId: Yup.number().min(1, 'Required field!'),
	vendorId: Yup.number().min(1, 'Required field!')
});

type Props = OwnProps & ReduxProps;

class CorrespondenceEditor extends ExtendableItemEditor<Correspondence, Props> {
	constructor(props: Props) {
		super(props);

		this.type = 'correspondence';
		this.path = 'getCorrespondence';
		

		this.defaultItem = { id: -1, injuryId: props.injury };

		this.additionalParams = {
			withAnswers: true
		};

		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.getUrl = this.getUrl.bind(this);
	}

	componentWillMount() {
		this.props.itemActions.loadItem(this.type, this.path, this.props.itemId, this.defaultItem, this.additionalParams);
		this.props.pagesActions.reqPages('shippingVendors', 'shippingVendorList', 'shippingVendor', { page: 1, text: '', count: 100 });
		this.props.pagesActions.reqPages('correspondenceTypes', 'correspondenceTypeList', 'correspondenceType', { page: 1, text: '', count: 100 });
	}

	componentWillReceiveProps(newProps) {
		if(newProps.itemId !== this.props.itemId) {
			this.props.itemActions.loadItem(this.type, this.path, newProps.itemId, this.defaultItem, this.additionalParams);
		}
	}
	
	getUrl(url, vendorId, trackingNumber): string | null {
		const {shippingVendors} = this.props;
		if (vendorId && trackingNumber && shippingVendors && shippingVendors.items) {
			for (let i = 0; i < shippingVendors.items.length; i++) {
				if (shippingVendors.items[i].id === vendorId) return shippingVendors.items[i].template && shippingVendors.items[i].template.replace('xxx', trackingNumber)
			}
		}
		return url;
	}

	handleSubmitForm(values: CorrespondenceFormValues, {resetForm}) {
		let time = moment(values.deliveredTime || undefined);
        
		if (values.time && typeof values.time === "string") {
			time.hour(+values.time.toString().split(':')[0]);
			time.minute(+values.time.toString().split(':')[1]);
        }

		let data = {
			...this.props.item,
			dropOffDate: values.dropOffDate || 0,
			vendorId: values.vendorId || 0,
			correspondenceTypeId: values.correspondenceTypeId || null,
			trackingNumber: values.trackingNumber || '',
			undeliverable: values.undeliverable.toString() === "true",
			undeliverableReason: values.undeliverableReason || '',
            deliveredTime: values.deliveredTime ? time.valueOf() : 0
		};
		
		request('correspondence', data).then((result) => {
			message.success('Correspondence was successfully added');

			resetForm();

			this.props.onSave && this.props.onSave();
		});
	}

	public render() {
		const item: Correspondence = this.props.item;
		
		const { injury: injuryId, shippingVendors, correspondenceTypes} = this.props;

		if (item && item.id === +this.props.itemId ) {
			
			const url = item.vendor && item.vendor.template && item.vendor.template.replace('xxx', item.trackingNumber);
			
			return (
				<React.Fragment>
					<Formik
						initialValues={{
							dropOffDate: item.dropOffDate || null,
							vendorId: item.vendorId || 0,
							correspondenceTypeId: item.correspondenceTypeId || 0,
							trackingNumber: item.trackingNumber || '',
							undeliverable: item.undeliverable || false,
							undeliverableReason: item.undeliverableReason || '',
							deliveredTime: item.deliveredTime || null,
							time: item.deliveredTime || null
						} as CorrespondenceFormValues}
						onSubmit={this.handleSubmitForm}
						enableReinitialize={true}
						validationSchema={validationSchema}
						render={(formikBag: FormikProps<CorrespondenceFormValues>) => (
							<form id={'new-statusReport-form'} onSubmit={e => { e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit() }}>
								<div className="row">
									<Field
										name="correspondenceTypeId"
										render={({ field, form }: FieldProps<CorrespondenceFormValues>) => (
											<div className={`form-group col-sm-6 ${form.errors.correspondenceTypeId && form.touched.correspondenceTypeId ? `has-error` : ``}`}>
												<label>Correspondence Type*</label>
												<div className="is-relative">
													<select className="form-control" value={field.value} {...field}>
														<option value="0">Select value</option>
														{correspondenceTypes && correspondenceTypes.items && correspondenceTypes.items.map(vendor => (
															<option value={vendor.id} key={vendor.id}>{vendor.name}</option>
														))}
													</select>
													{form.errors.correspondenceTypeId && form.touched.correspondenceTypeId ? <div className="validation-message">{form.errors.correspondenceTypeId}</div> : ''}
												</div>
											</div>
										)}
									/>
									<Field
										name="undeliverable"
										render={({ field, form }: FieldProps<CorrespondenceFormValues>) => (
											<div className="form-group col-sm-6">
												<label>Undeliverable</label>
												<select className="form-control" {...field}>
													<option value={"false"}>No</option>
													<option value={"true"}>Yes</option>
												</select>
											</div>
										)}
									/>
								</div>
								<div className="row">
									<div className="form-group col-sm-6">
										<label>Date Sent</label>
										<Datepicker
											value={formikBag.values.dropOffDate}
											format="MM/DD/YY"
											onChange={(date) => {
												formikBag.setFieldValue('dropOffDate', date)
											}}
										/>
									</div>
									<Field
										name="vendorId"
										render={({field, form}: FieldProps<CorrespondenceFormValues>) => (
											<div className={`form-group col-sm-6 ${form.errors.vendorId && form.touched.vendorId ? `has-error` : ``}`}>
												<label>Shipping Method*</label>
												<div className="is-relative">
													<select className="form-control" value={field.value} {...field}>
														<option value="0">Select value</option>
														{shippingVendors && shippingVendors.items && shippingVendors.items.map(vendor => (
															<option value={vendor.id} key={vendor.id}>{vendor.name}</option>
														))}
													</select>
													{form.errors.vendorId && form.touched.vendorId ? <div className="validation-message">{form.errors.vendorId}</div> : ''}
												</div>
											</div>
										)}
									/>
								</div>
								<div className="row">
									<Field
										name="trackingNumber"
										render={({ field, form }: FieldProps<CorrespondenceFormValues>) => (
											<div className={`form-group col-sm-6`}>
												<label>Tracking Number</label>
												<div className="is-relative">
													<input className="form-control" type="text" value={item.trackingNumber} {...field}/>
												</div>
											</div>
										)}
									/>
									<div className="form-group col-sm-6">
										<label>Check Status</label>
										<div className="is-relative">
											<a href={this.getUrl(url, formikBag.values.vendorId, formikBag.values.trackingNumber) || undefined} target="_blank">Tracking</a>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-sm-6">
										<label>Date Received</label>
										<Datepicker
											value={formikBag.values.deliveredTime}
											format="MM/DD/YY"
                                            onChange={(date) => {
												formikBag.setFieldValue('deliveredTime', date)
											}}
										/>
									</div>
									<div className="form-group col-sm-6">
										<div>
											<label>Time Received</label>
											<Field
												name="time"
												render={({field, form}: FieldProps<CorrespondenceFormValues>) => (
													<div className="is-relative">
														<Timepicker 
															antdProps={timepickerProps}
															value={field.value}
															onChange={(date) => {
																formikBag.setFieldValue('time', date)
															}}
														/>
													</div>
												)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<Field
										name="undeliverableReason"
										render={({ field, form }: FieldProps<CorrespondenceFormValues>) => (
											<div className="form-group col-sm-12">
												<label>Undeliverable Reason</label>
												<textarea className="form-control" value={item.undeliverableReason} {...field}/>
											</div>
										)}
									/>
								</div>
								{this.props.editable && <div className="text-right form-group">
									<Button isLoading={this.state.isLoading} className="btn btn-success">Save</Button>
									<button type="button" className="btn btn-danger" onClick={() => {
										formikBag.resetForm();
										this.props.onCancel();
									}}>Cancel</button>
								</div>}
								{this.messages()}
							</form>
						)}
					/>
				</React.Fragment>
			)
		}

		return <Loading />
	}
}

export default connect<ReduxProps, any, OwnProps>(
	(state: ApplicationState) => ({
		...state.correspondence,
		shippingVendors: state.shippingVendors,
		correspondenceTypes: state.correspondenceTypes
	}),
	dispatchToProps
)(CorrespondenceEditor);