import * as React from 'react';

import {request} from '@app/components/Api';

import {List} from "@common/typescript/objects/List";

import {dateFormat} from "@app/components/Utils/Utils";
import {StatusReport} from "@app/objects/StatusReport";
import Modal from "antd/lib/modal";

import StatusReportEditor from "./StatusReportEditor";
import {deleteConfirmation} from "@app/components/Utils/Modal";

import message from 'antd/lib/message';
import {Injury, InjuryStatus} from "@app/objects/Injury";
import {UserRole} from "@app/objects/User";

import ViewStatusReport from "@app/components/Pages/StatusReports/ViewStatusReport";
import {dateTimeFormat} from "@common/typescript/Utils";


interface CallsProps {
	injury: Injury;
	userRole: number;
	isEditable: boolean;
}

interface CallsState {
	isLoading: boolean;
	reports: List<any> | null;
	visible: boolean;
	reportId: number;
	visibleView: boolean;
	statusReport: StatusReport | null;
}

export default class StatusReports extends React.Component<CallsProps, CallsState> {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			reports: null,
			visible: false,
			reportId: -1,
			visibleView: false,
			statusReport: null
		};
		
		this.loadReports = this.loadReports.bind(this);
		this.openReport = this.openReport.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onReportSave = this.onReportSave.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.onViewStatusReport = this.onViewStatusReport.bind(this);
		this.countDaysInProgram = this.countDaysInProgram.bind(this);
	}

	
	loadReports() {
		this.setState({ isLoading: true });

		request('statusReportList', {
			injury: this.props.injury.id
		}).then((result: any) => {
			this.setState({
				isLoading: false,
				reports: result
			});
		});
	}
	
	componentDidMount() {
		this.loadReports();
	}
	
	openReport(id: number): void {
		this.setState({
			reportId: id,
			visible: true
		})
	}
	
	closeDialog() {
		this.setState({
			visible: false,
			visibleView: false
		})
	}
	
	onReportSave() {
		this.loadReports();
		this.closeDialog();

		message.success('Report has been successfully saved!');
	}
	
	
	onCancel() {
		this.closeDialog()
	}
	
	handleDelete(report: StatusReport) {
		deleteConfirmation(() => {
			request('statusReport', {...report, deleted: true}).then(() => {
				this.setState((state, props) => {
					if(state.reports !== null) {
						return {
							reports: {
								count: state.reports.count - 1,
								execution: 0,
								offset: 0,
								list: state.reports.list.filter((item: StatusReport) => item.id !== report.id)
							}
						}
					}
					
					return state.reports;
				});
			});
		});
	}

	onViewStatusReport(statusReport: StatusReport){
		this.setState({
			reportId: statusReport.id,
			visibleView: true,
			statusReport: statusReport
		});
	}

	countDaysInProgram(statusReport: StatusReport) {
		if (statusReport.injury && statusReport.injury.schedules && statusReport.injury.schedules.list) {
			const schedules = statusReport.injury.schedules.list;
			const oneDay = 24 * 60 * 60 * 1000;
			if (schedules.length > 1) {
				const times = schedules.map(schedule => schedule.startTime);
				const minTime = Math.min.apply(null, times);

				return Math.round((Date.now() - minTime) / oneDay) > 0 ? Math.round((Date.now() - minTime) / oneDay).toString() : '0';
			} else if (schedules.length === 1) {
				return Math.round((Date.now() - schedules[0].startTime) / oneDay) > 0 ? Math.round((Date.now() - schedules[0].startTime) / oneDay).toString() : '0';
			}
		}
		return '0';
	}

	render() {
		const {reports, reportId, visible, visibleView, statusReport} = this.state;
		
		const {injury, userRole, isEditable} = this.props;

		return (
			<div className="status-reports">
				{isEditable && userRole !== UserRole.External && <div className="form-group text-right">
					<button className="btn btn-primary" type="button" onClick={e => this.openReport(-1)}>Add Journal
					</button>
				</div>}
				{reports && reports.list.length > 0 
					? <div className="ant-table small-padding-table"><table>
						<thead className="ant-table-thead">
						<tr>
							<th>Date</th>
							<th></th>
						</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{reports.list.map((report: StatusReport) => {
								return <tr key={report.id}>
									<td>{dateFormat(report.time)}</td>
									<td>
										<div className="table-actions">
											<button type="button" className="btn btn-sm btn-default" title="View Case" onClick={e => this.onViewStatusReport(report)}><i className="fa fa-eye"></i></button>
											{isEditable && <React.Fragment>
													<button type="button" className="btn btn-sm btn-default" title="Edit" onClick={e => this.openReport(report.id)}><i className="fa fa-edit"></i></button>
													<button type = "button" className="btn btn-sm btn-danger" title="Delete" onClick={e => this.handleDelete(report)}><i className="fa fa-trash"></i></button>
											</React.Fragment>}
										</div>
									</td>
								</tr>
							})}
						</tbody>
					</table></div>
					: <div className="not-found-title">Reports not found</div>
				}
				<Modal
					title={reportId > 0 ? 'Edit Status Report' : 'Add New Report'}
					visible={visible}
					onCancel={this.closeDialog}
					width={600}
					footer={null}
				>
					<StatusReportEditor injury={injury.id} reportId={reportId} onSave={this.onReportSave} onCancel={this.onCancel} editable={userRole !== UserRole.External}/>
				</Modal>
				<Modal
					title={statusReport ? dateFormat(statusReport.time) + ' ' + dateTimeFormat(statusReport.time) : ''}
					visible={visibleView}
					onCancel={this.closeDialog}
					width={1400}
					footer={null}
					destroyOnClose={true}
				>
					{statusReport &&
					<ViewStatusReport onCancel={this.closeDialog} reportId={reportId} daysInProgram={this.countDaysInProgram(statusReport)} injury={injury.id}/>}
				</Modal>
			</div>
		);
	}
}