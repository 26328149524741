import * as React from "react";
import {StatusHistory} from "@app/objects/StatusHistory";
import {dateFormat, getUserName} from "@app/components/Utils/Utils";
import {getInjuryStatusName, Injury, InjuryStatus} from "@app/objects/Injury";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import Modal from "antd/lib/modal";

import {ActionCreatorsMapObject, bindActionCreators} from "redux";

import PendingForm from './PendingForm';
import {getSelectedInjuryStatusHistoryActionCreators} from "@app/store/InjuryStatusHistory";
import FollowUp, {Recurrence} from "@app/objects/FollowUp";
import {request} from "@app/components/Api";
import {deleteConfirmation} from "@app/components/Utils/Modal";
import {User} from "@app/objects/User";
import {FollowUpDoneCheckbox} from "@app/components/Pages/Employees/InjuryStatusForm/FollowUpDoneCheckbox";
import {Reminder} from "@app/objects/Reminder";

interface OwnProps {
	injury: Injury;
	statuses: StatusHistory[];
	updateInjury: (oldInjury: Injury, newInjury: Injury) => void;
}

interface ReduxProps {
	user: User | null;
}

type Props = ReduxProps & OwnProps & {
	injuryStatusHistoryActions: ActionCreatorsMapObject;
};

interface ComponentState{
	editId: number | null;
	isLoading: boolean;
}

class InjuryStatusHistory extends React.Component<Props, ComponentState> {
	constructor(props: Props){
		super(props);
		
		this.state = {
			editId: null,
			isLoading: false
		};
		
		this.openDialog = this.openDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onSaveDialog = this.onSaveDialog.bind(this);
		this.removeFollowUp = this.removeFollowUp.bind(this);
		this.onFollowUpDone = this.onFollowUpDone.bind(this);
	}
	
	openDialog(id: number) {
		this.setState({
			editId: id
		})
	}
	
	refreshHistory() {
		this.props.injuryStatusHistoryActions.refreshPages('injuryStatusHistory', 'statusHistoryList', {
			page: 1,
			withReason: true,
			withUser: true,
			withOutcome: true,
			withFollowUps: true,
			objectId: this.props.injury.id
		})
	}
	
	removeFollowUp(followUp: FollowUp) {
		deleteConfirmation(() => {
			let item = {
				...followUp,
				followUpType: null,
				deleted: true,
				statusHistory: null
			};

			request<FollowUp>('followUp', item).then((result: FollowUp) => {
				this.refreshHistory();
			})
		});
		
	}
	
	closeDialog() {
		this.setState({editId: null})
	}
	
	onSaveDialog(item: StatusHistory) {
		this.closeDialog();
		
		this.props.injuryStatusHistoryActions.updateItem('injuryStatusHistory', item);
	}
	
	onFollowUpDone(followUp: FollowUp | Reminder) {
		this.refreshHistory();
	}
	
	render() {
		const {injury, user} = this.props;
		
		const editId = this.state.editId;
		
		const statuses = this.props.statuses || [];
		
		const recurrence = {
			[Recurrence.OneTime]: 'One time',
			[Recurrence.ByWeekly]: 'Bi-Weekly',
			[Recurrence.Daily]: 'Daily',
			[Recurrence.Monthly]: 'Monthly',
			[Recurrence.Weekly]: 'Weekly'
		};
		
		return statuses.length > 0 
				? <ul className="status-history">{statuses.map((item: StatusHistory, index) => <li className="status-history__item" key={item.id}>
					{dateFormat(item.time)} to <strong>{getInjuryStatusName(user, item.injuryStatus)}</strong> by {item.user ? (item.user.firstName ? getUserName(item.user) : item.user.email) : ''}
					{item.injuryStatus === InjuryStatus.Pending &&
					<div className="status-history__info">
						<div className="status-history__actions">
							<i className="fa fa-pencil" onClick={e => this.openDialog(item.id)}></i>
						</div>
						<strong>Reason:</strong> {item.pendingReason ? item.pendingReason.name : ''} <br/>
						<strong>Note:</strong> {item.note}<br />
						<strong>FollowUp Date:</strong> {dateFormat(injury.followUpDate)}
						{editId === item.id && <Modal
							title={'Edit pending status'}
							onCancel={this.closeDialog}
							width={600}
							visible={true}
							footer={null}>
							<PendingForm injury={injury} item={item} onSave={this.onSaveDialog} updateInjury={this.props.updateInjury}/>
						</Modal>}
					</div>
					}
					{item.injuryStatus === InjuryStatus.FollowUp && item.followUps && item.followUps.length > 0 &&
					<ul className="status-history__followUps">
						{item.followUps.map((followUp: FollowUp) => 
							<li key={followUp.id}>
								<div className="is-relative">
									{followUp.followUpType ? followUp.followUpType.name : ''} - {followUp.date ? dateFormat(followUp.date, true) : ''} - {recurrence[followUp.recurrence]} by {followUp.user &&  <strong>{followUp.user.firstName ? getUserName(followUp.user) : followUp.user.email}</strong> }
									{injury.assignedPerson ? <React.Fragment> for <strong> {getUserName(injury.assignedPerson)}</strong></React.Fragment> : ''}
									{followUp.done ? ` and completed ${dateFormat(followUp.doneTime, true)}` : ''}
									<FollowUpDoneCheckbox item={followUp} onCheck={this.onFollowUpDone} />
									<i className="fa fa-times" onClick={e => this.removeFollowUp(followUp)}></i>
								</div>
								
								{followUp.reminders.length > 0 && <ul className="status-history__followUps-reminders">
									{followUp.reminders.map((item: Reminder) => (
										<li key={item.id}>{dateFormat(item.start)} <FollowUpDoneCheckbox item={item} type="reminder" onCheck={this.onFollowUpDone} /></li>
									))}
								</ul>}
							</li>
						)}
					</ul>}
					{item.injuryStatus === InjuryStatus.Closed &&
						<div className="status-history__info">
							<strong>Job:</strong> {item.schedule && item.schedule.company ? item.schedule.company.name : '-'} <br/>
							<strong>Outcome:</strong> {item.outcomeType ? item.outcomeType.name : ''}
							{item.lastDayWorked && <React.Fragment><br/><strong>Last Date Worked: </strong>{dateFormat(item.lastDayWorked)}</React.Fragment>}
							{item.rtwDate && <React.Fragment><br/><strong>RTW Date: </strong>{dateFormat(item.rtwDate)}</React.Fragment>}
						</div>}
				</li>)}</ul>	
				: <div className="not-found-title">History is empty</div>;
	}
}

export default connect<ReduxProps, {}, OwnProps>((state: ApplicationState) => ({
		user: state.login.user
	}),
(dispatch, ownProps?: OwnProps) => {
		if(ownProps && ownProps.injury) {
			return {
				injuryStatusHistoryActions: bindActionCreators(getSelectedInjuryStatusHistoryActionCreators(ownProps.injury.id), dispatch)
			};
		}
		
		return {};
}
)(InjuryStatusHistory as any)