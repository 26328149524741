import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '@app/store/index';

import { dispatchToProps, ExtendableItemEditor } from "@app/components/Pages/ItemEditor";

import { Formik, FormikProps, Field, FieldProps, FieldArray } from 'formik';

import Button from "@common/react/components/Forms/Button";
import { Loading } from "@app/components/UI/Loading";

import { ItemsState } from "@common/react/store/ItemList";
import { CustomQuestion, getMergedQuestions, Question } from "@app/objects/Question";
import { StatusReport } from "@app/objects/StatusReport";

import Event from "@app/objects/Event";
import { Answer } from "@app/objects/Answer";
import CommentTree from "@app/components/Forms/Comment/CommentTree";
import { Select } from 'antd';

interface ReduxProps {
    questions: ItemsState<Question>
}

interface OwnProps {
    injury: number;
    reportId: number;
    onSave: () => void;
    onCancel: () => void;
    editable: boolean;
}


export interface StatusReportFormValues {
    type: string[];
    general: string[];
    challenges: string[];
    attendance: string;
    workPerformance: string;
    meetingWorksiteGuidelines: string;
}

type Props = OwnProps & ReduxProps;

class StatusReportEditor extends ExtendableItemEditor<StatusReport, Props> {
    constructor(props: Props) {
        super(props);

        this.type = 'statusReport';
        this.path = 'getStatusReport';
        this.backPath = '/companies';

        this.itemsStore = 'statusReports';
        this.itemsPath = 'statusReportList';

        this.defaultItem = { id: -1, injuryId: props.injury, answers: { count: 0, list: [], execution: 0, offset: 0 } };

        this.additionalParams = {
            withAnswers: true
        };

        this.state = {
            ...this.state,
            showGeneral: undefined,
            showChallenges: undefined,
            showWSR: undefined,
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentWillMount() {
        this.props.itemActions.loadItem(this.type, this.path, this.props.reportId, this.defaultItem, this.additionalParams);
        this.props.pagesActions.reqPages('questions', 'questionList', 'question', { page: 1, text: '', count: 100 });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.reportId !== this.props.reportId) {
            this.props.itemActions.loadItem(this.type, this.path, newProps.reportId, this.defaultItem, this.additionalParams);
        }
    }

    handleSubmitForm(values: StatusReportFormValues, { resetForm }) {
        console.log(values);


        //if(isValid) {
        let item = this.props.item;



        const newAnswers = [{
            text: JSON.stringify(values),
            questionId: 11,
            statusReportId: this.props.reportId,
            deleted: false,
            id: this.id
        }]
        item = { ...(item as any), answers: newAnswers, children: null };

        this.saveItem(item).then((response: StatusReport) => {
            this.hideSuccess();
            this.props.onSave();

            this.updateItem(response);
        });
        // } else {
        // 	this.setState({
        // 		error: 'You must select at least one item',
        // 		isLoading: false
        // 	});
        //	
        // 	return false;
        // }
    }




    id: number | undefined = undefined;
    public render() {
        const item: StatusReport = this.props.item;

        const questions = this.props.questions.items;

        const injuryId = this.props.injury;
        let { showGeneral, showWSR, showChallenges } = this.state
        if (item && item.id === +this.props.reportId && questions.length > 0) {

            const currentAnswers = item.answers ? item.answers.list : [];
            if (currentAnswers.length > 0) {
                this.id = currentAnswers[0].id;
            }
            //console.log(currentAnswers);
            const vals = currentAnswers.length > 0 && currentAnswers[0].questionId == 11 ? JSON.parse(currentAnswers[0].text) : undefined;
            if (vals && showGeneral == undefined) {
                showGeneral= vals.type.join().indexOf("0") >= 0;
                showChallenges= vals.type.join().indexOf("1") >= 0;
                showWSR = vals.general.join().indexOf("WSR") >= 0;
            }
            
            return (
                <React.Fragment>
                    <Formik
                        initialValues={{
                            type: vals ? vals.type : [],
                            general: vals ? vals.general : [],
                            challenges: vals ? vals.challenges : [],
                            attendance: vals ? vals.attendance : "S",
                            workPerformance: vals ? vals.workPerformance : "S",
                            meetingWorksiteGuidelines: vals ? vals.meetingWorksiteGuidelines : "S"
                        }}
                        onSubmit={this.handleSubmitForm}
                        enableReinitialize={true}
                        render={(formikBag: FormikProps<StatusReportFormValues>) => (
                            <form id={'new-statusReport-form'} onSubmit={e => { e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit() }}>
                                <Field
                                    name={`type`}
                                    render={({ field, form }: FieldProps<Event>) => (
                                        <div className="form-group">



                                            <label className="toggle-checkbox__label toggle-checkbox__label__sm">Choose</label>

                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Please select"
                                                onChange={(value: string[]) => {

                                                    console.log("Aa", formikBag.values.general)
                                                    this.setState({
                                                        showGeneral: value.join().indexOf("0") >= 0,
                                                        showChallenges: value.join().indexOf("1") >= 0,
                                                        showWSR: formikBag.values.general.join().indexOf("WSR") >= 0
                                                    });
                                                    formikBag.setFieldValue("type", value);
                                                }}
                                                value={field.value}
                                            >
                                                <option key="0">General</option>
                                                <option key="1">Challenges</option>
                                            </Select>
                                        </div>
                                    )}
                                />
                                <Field
                                    name={`general`}
                                    render={({ field, form }: FieldProps<Event>) => (

                                        <div className="form-group" hidden={!showGeneral} >
                                            <label className="toggle-checkbox__label toggle-checkbox__label__sm">General</label>

                                            <Select
                                                mode="multiple"
                                                placeholder="Please select"
                                                onChange={(value: string[]) => {
                                                    this.setState({
                                                        showWSR: value.join().indexOf("WSR") >= 0
                                                    });
                                                    formikBag.setFieldValue("general", value);
                                                }}
                                                value={field.value}
                                            >
                                                <option key="Please provide updated medical status report">Please provide updated medical status report</option>
                                                <option key="Worksite Supervisor Review (WSR)">Worksite Supervisor Review (WSR)</option>
                                            </Select>
                                        </div>
                                    )}
                                />

                                <div className="form-group" hidden={!showWSR || !showGeneral} >
                                    <Field
                                        name={`attendance`}
                                        render={({ field, form }: FieldProps<Event>) => (
                                            <div>
                                                <label className="toggle-checkbox__label toggle-checkbox__label__sm">Attendance</label>

                                                <Select
                                                    placeholder="Please select"
                                                    defaultValue={'S'}
                                                    value={field.value}
                                                    onChange={(value: string) => {
                                                        formikBag.setFieldValue("attendance", value);
                                                    }}
                                                >
                                                    <option key="S">S</option>
                                                    <option key="U">U</option>
                                                </Select>
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name={`workPerformance`}
                                        render={({ field, form }: FieldProps<Event>) => (
                                            <div>
                                                <label className="toggle-checkbox__label toggle-checkbox__label__sm">Work Performance</label>

                                                <Select
                                                    placeholder="Please select"
                                                    onChange={(value: string) => {
                                                        formikBag.setFieldValue("workPerformance", value);
                                                    }}
                                                    defaultValue={'S'}

                                                    value={field.value}
                                                >
                                                    <option key="S">S</option>
                                                    <option key="U">U</option>
                                                </Select>
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name={`meetingWorksiteGuidelines`}
                                        render={({ field, form }: FieldProps<Event>) => (
                                            <div>

                                                <label className="toggle-checkbox__label toggle-checkbox__label__sm">Meeting Worksite Guidelines</label>

                                                <Select
                                                    placeholder="Please select"
                                                    defaultValue={'S'}

                                                    value={field.value}
                                                    onChange={(value: string) => {
                                                        formikBag.setFieldValue("meetingWorksiteGuidelines", value);
                                                    }}
                                                >
                                                    <option key="S">S</option>
                                                    <option key="U">U</option>
                                                </Select>
                                            </div>
                                        )}
                                    />
                                </div>
                                <Field
                                    name={`challenges`}
                                    render={({ field, form }: FieldProps<Event>) => (

                                        <div className="form-group" hidden={!showChallenges} >
                                            <label className="toggle-checkbox__label toggle-checkbox__label__sm">Challenges</label>

                                            <Select
                                                mode="multiple"
                                                placeholder="Please select"
                                                onChange={(value: string) => {
                                                    formikBag.setFieldValue("challenges", value);
                                                }}
                                                value={field.value}
                                            >
                                                <option key="Excessive Personal Cell Phone Usage">Excessive Personal Cell Phone Usage</option>
                                                <option key="Excessive Breaks">Excessive Breaks</option>
                                                <option key="Not Working Schedule">Not Working Schedule</option>
                                                <option key="Missed Days - No Call/No Show">Missed Days - No Call/No Show</option>
                                                <option key="Poor Work Performance with Job Tasks">Poor Work Performance with Job Tasks</option>
                                                <option key="Failure to Use VARZIS Time Tracking System">Failure to Use VARZIS Time Tracking System</option>
                                            </Select>
                                        </div>
                                    )}
                                />
                                {this.props.editable && <div className="text-right form-group">
                                    <Button isLoading={this.state.isLoading} className="btn btn-success">Save</Button>
                                    <button type="button" className="btn btn-danger" onClick={() => {
                                        formikBag.resetForm();
                                        this.props.onCancel();
                                    }}>Cancel</button>
                                </div>}
                                {this.messages()}
                            </form>
                        )}
                    />
                    {item.id > 0 && <React.Fragment>
                        <hr />
                        <h4>Comments</h4>
                        <CommentTree objectType="statusReport" objectId={item.id} withoutAddButton={true} insertBefore={true} />
                    </React.Fragment>}
                </React.Fragment>
            )
        }

        return <Loading />
    }
}

export default connect<ReduxProps, any, OwnProps>(
    (state: ApplicationState) => ({
        ...state.statusReport,
        questions: state.questions
    }),
    dispatchToProps
)(StatusReportEditor);