import * as React from "react";

import * as Yup from 'yup';

import * as PagesState from '@app/store/ItemList';
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import Schedule from "@app/objects/Schedule";
import ScheduleContact, {UserOrCompanyContact} from "@app/objects/ScheduleContact";
import {ExtendableItemsPage} from "@app/components/Pages/ItemsPage";
import {phoneFormat} from "@app/components/Utils/Utils";
import {Injury} from "@app/objects/Injury";
import Table from "antd/lib/table";

import Autocomplete from "@common/react/components/Forms/Autocomplete/Autocomplete";
import Select from "antd/lib/select";
import {request} from "@app/components/Api";
import message from "antd/lib/message";
import {Employee} from "@app/objects/Employee";
import {ActionCreatorsMapObject} from "redux";

interface OwnProps {
	injury?: Injury,
	employee?: Employee,
	schedule: Schedule
}

const Option = Select.Option;

const validationSchema = Yup.object().test('contact', 'Required field!', value => value.userId > 0 || value.companyContactId > 0);

interface ComponentState {
	selectedContact: UserOrCompanyContact | null;
	error: string | null;
}

class InjuryContacts extends ExtendableItemsPage<ScheduleContact, OwnProps, ComponentState> {
	contactName: string = '';
	contactNameCurrentInputValue: string = '';
	
	
	constructor(props: any) {
		super(props); 
		
		this.columns = [
			{
				title: 'First Name',
				dataIndex: 'firstName',
				render: (text: string, record: ScheduleContact) => this.renderMobileCell('FirstName', this.getContactPropValue(record, 'firstName'))
			},
			{
				title: 'Last Name',
				dataIndex: 'lastName',
				render: (text: string, record: ScheduleContact) => this.renderMobileCell('Last name', this.getContactPropValue(record, 'lastName'))
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				render: (text: string, record: ScheduleContact) => this.renderMobileCell('Phone', phoneFormat(this.getContactPropValue(record, 'phone')))
			},
			{
				title: 'Email',
				dataIndex: 'email',
				render: (text: string, record: ScheduleContact) => this.renderMobileCell('Email', this.getContactPropValue(record, 'email'))
			},
			{
				title: 'Title',
				dataIndex: 'title',
				render: (text: string, record: ScheduleContact) => record.companyContact && record.companyContact.title && record.companyContact.title.name || '-'
			},
			{
				title: '',
				render: (text, record) => {
					return (
						<div className="text-right table-action">
							<div>
								<button className="btn btn-sm btn-danger"  type="button" title="Delete" onClick={e => this.handleDelete(e, record)}><i className="fa fa-trash"/></button>
							</div>
						</div>
					)
				}
			}
		];
		
		this.state = {
			selectedContact: null,
			error: null
		};
		
		this.type = 'scheduleContact';
		this.store = 'scheduleContacts';
		this.path = 'scheduleContactList';
		this.tableClassName = 'small-padding-table';
		
		this.handleAdd = this.handleAdd.bind(this);
	}

	handleTableChange(pagination, filters, sorter) {
		this.props.reqPages(this.store, this.path, this.type, { page: pagination.current, objectId: this.props.schedule.id});
	}
	
	getContactPropValue(contact: ScheduleContact, prop: string): string {
		return (contact.user ? contact.user[prop] : contact.companyContact && contact.companyContact[prop]) || '-';
	}

	getUserOrCompanyContactName(contact: UserOrCompanyContact): string {
		if (!contact) {
			return '';
		}
		
		return contact.user
			? `${contact.user.firstName} ${contact.user.lastName} (${contact.user.email})` 
			: contact.companyContact 
				? `${contact.companyContact.firstName} ${contact.companyContact.lastName} (${contact.companyContact.email})`
				: '';
	}
	
	componentWillReceiveProps(nextProps: OwnProps) {
		if(this.props.schedule.id !== nextProps.schedule.id) {
			this.props.reqPages(this.store, this.path, this.type, {objectId: nextProps.schedule.id});
		}
	}
	
	componentWillMount() {
		this.props.reqPages(this.store, this.path, this.type, {objectId: this.props.schedule.id});
	}
	
	handleAdd() {
		if (this.contactName != this.contactNameCurrentInputValue) {
			message.error('You should search and select contact from available list')
		} else {
			const selectedContact = this.state.selectedContact;
			
			if(selectedContact) {
				const user = selectedContact.user;
				const companyContact = selectedContact.companyContact;

				const newItem = {
					scheduleId: this.props.schedule.id,
					userId: user && user.id,
					companyContactId: companyContact && companyContact.id
				};

				validationSchema.validate(newItem).then(() => {
					request('scheduleContact', newItem).then((response) => {
						this.props.refreshPages(this.store, this.path);

						this.setState({
							selectedContact: null
						});

						this.contactName = '';
						this.contactNameCurrentInputValue = '';
					});
				}).catch((err) => {
					this.setState({
						error: err.errors[0]
					});
				});
			}
		}
	}
	
	render() {
		const {items, pagination, isLoading, schedule} = this.props;
		
		const {selectedContact} = this.state;
		var _tblclass = "contect-tbl";
		return schedule && <React.Fragment>
				<div className="clearfix form-group">
					<div className={`pull-left ${this.state.error ? 'has-error' : ''}`}>
						<Autocomplete
							type="alertReceiverList"
							renderOption={(autocompleteItem) =>
								<Option
									{...{
										key: this.getUserOrCompanyContactName(autocompleteItem),
										value: this.getUserOrCompanyContactName(autocompleteItem),
										title: this.getUserOrCompanyContactName(autocompleteItem),
										item: autocompleteItem
									}}>{this.getUserOrCompanyContactName(autocompleteItem)}</Option>
							}
							onChange={(value) => {
								if (!value) {
									this.contactName = '';
								}
								this.contactNameCurrentInputValue = value;
								this.setState({error: null}) 
							}}
							onSelect={(value, option) => {
								this.contactName = this.getUserOrCompanyContactName(option.props.item);
								this.contactNameCurrentInputValue = this.contactName;
								this.setState({selectedContact: option.props.item});
							}}
							value={selectedContact ? this.getUserOrCompanyContactName(selectedContact) : ''}
							paramName="text"
							params={{
								nonProfitId: schedule ? schedule.companyId : 0,
								referralId: this.props.employee && this.props.employee.referralId
							}}
							loadOnFocus={true}
							antdProps={{
								placeholder:'contact name'
							}}
						/>
						{this.state.error && <div className="validation-message">{this.state.error}</div>}
					</div>
					<div className="pull-left">
						<button type="button" className="btn btn-primary btn-with-input" onClick={this.handleAdd}>Add Contact</button>
					</div>
				</div>
				<div className="row" style={{marginTop: "20px"}}>
					<div className="col-sm-12">
						<Table
							columns={this.columns}
							dataSource={items}
							pagination={pagination}
							loading={isLoading}
							onChange={this.handleTableChange}
							childrenColumnName="child"
							rowKey="id"
						className={this.tableClassName + " " + _tblclass}
						/>
					</div>
				</div>
			</React.Fragment>
	}
}

export default connect<PagesState.ItemsState<ScheduleContact>, ActionCreatorsMapObject, OwnProps>(
	(state: ApplicationState, ownProps?: OwnProps) => ({
		...state.scheduleContacts
	}),
	PagesState.getActionCreators<ScheduleContact>()
)(InjuryContacts);