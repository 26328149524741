import * as React from 'react';
import loadable from '@loadable/component'
import { Company, CompanyPhone, CompanyWithoutDecimalValues } from "@app/objects/Company";
import { connect } from "react-redux";
import { ApplicationState } from "@app/store";
import * as PagesState from "@app/store/ItemList";

import { Employee, PlaceList } from "@app/objects/Employee";
import * as PageState from '@common/react/store/Item'
import { MarkerObject } from '@app/components/UI/Map/Map';
import { params } from "@app/loadableSettings";
const Map = loadable(
    () => import(/* webpackChunkName: "MapComponent" */ './../../UI/Map/Map'), params
);

import { Injury } from "@app/objects/Injury";
import AddEventPopup from "@app/components/Pages/Employees/InjurySchedule/AddEventPopup";
import { getEventActionCreators } from "@app/store/Event";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";
import Event from "@app/objects/Event";
import Slider from "antd/lib/slider";
import * as EmployeeCommentsObjectState from "@app/store/EmployeeCommentsObject";
import { User, UserRole } from "@app/objects/User";
import { Link } from "react-router-dom";
import Modal from "antd/lib/modal";
import Popover from 'antd/lib/popover';
import CallLogEditor from '@app/components/Pages/Companies/CallLogEditor';
import * as ItemsState from '@common/react/store/ItemList';
import { GoogleMapButton } from "@app/components/UI/GoogleMapButton/GoogleMapButton";
import { request, allRequest } from "@app/components/Api";
import { List } from "@common/typescript/objects/List";
import Schedule from "@app/objects/Schedule";
import { getUserName, googleApiKey } from "@app/components/Utils/Utils";
import Button from "@common/react/components/Forms/Button";
import { fetch } from 'domain-task';
import axios from 'axios';
import {
    GoogleMap,
    withGoogleMap,
    withScriptjs,
    Marker,
    InfoWindow,
    WithGoogleMapProps,
    WithScriptjsProps
} from "react-google-maps";

interface ReduxProps {
    companies: PagesState.ItemsState<Company>,
    employee: PageState.ItemState<Employee>,
    events: PagesState.ItemsState<Event>,
    commentsObject: EmployeeCommentsObjectState.EmployeeCommentsObjectState
}

interface OwnProps {
    injury: Injury,
    user: User | null,
    onJobAdd?: () => void,
    onCallAdd?: (companyId: number) => void,

}

interface Actions {
    actions: ActionCreatorsMapObject,
    commentsObjectAction: EmployeeCommentsObjectState.EmployeeCommentsObjectCreators,
    companiesActions: ActionCreatorsMapObject
}

interface LoadedEmployee {
    [key: string]: {
        items: Employee[],
        loaded: boolean

    }
}

declare const google: any;
let map;
let service;
let infowindow;
var placeAddresses: any;
var places: any;
var SelectedItems: any;
const refs = {
    map: Map,
}


//interface CompanyFormValues extends CompanyWithoutDecimalValues {
//    placementFee1: string;
//    placementFee2: string;
//    placementFee3: string;
//    phonesFields: CompanyPhone[];
//}

interface ComponentState {
    addSchedulePopupVisible: boolean;
    addCallPopupVisible: boolean;
    loadedEmployee: LoadedEmployee;
    placeNearby: [];
    success: boolean,
    error: null,
    employee: {}
}

type ComponentProps = Company & ReduxProps & OwnProps & Actions;
var markers: MarkerObject[];

class EmployeeCompanyList extends React.PureComponent<ComponentProps, ComponentState> {
    sliderMarks = {
        5: '5',
        15: '15',
        25: '25',
        35: '35',
        45: '45',
        55: '55',
        65: '65',
        75: '75',
        85: '85',
        95: '95',
        105: '105',
        115: '115',
        125: '125'
    };

    Checkitems = [
        'Non-Profits', 'Not for Profits', 'Thrift Stores', 'Senior Centers', 'Volunteer', 'Habitat Restore', 'Food Pantry', 'Animal Shelter', 'Museum'
    ];



    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            addSchedulePopupVisible: false,
            addCallPopupVisible: false,
            loadedEmployee: {},
            placeNearby: [],
            success: false,
            error: null,
            employee: {}
        };


        this.markerClick = this.markerClick.bind(this);
        this.onEventSave = this.onEventSave.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.cancelPopup = this.cancelPopup.bind(this);
        this.onCallLogSave = this.onCallLogSave.bind(this);
        this.openCallLogPopup = this.openCallLogPopup.bind(this);
        this.loadWorksiteEmployees = this.loadWorksiteEmployees.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.OnLoadLocation = this.OnLoadLocation.bind(this);
        this.handleCreateCompany = this.handleCreateCompany.bind(this);
        this.handleChange = this.handleChange.bind(this);
        SelectedItems = [];
        this.Checkitems.map((place) => {
            SelectedItems.push(place);
        });
    }

    markerClick(company: Company) {
        this.props.commentsObjectAction.setCommentsObject({ objectType: 'company', id: company.id, caption: 'Company' });
    }

    MarkerClick(company: PlaceList) {
        //this.props.commentsObjectAction.setCommentsObject({ objectType: 'place', id: company.id, caption: 'Near By Place' });
        this.loadPlaceDetails(company.place_id);
    }

    onCallLogSave() {
        if (this.openedCompanyId) {
            this.props.onCallAdd && this.props.onCallAdd(this.openedCompanyId);
        }

        this.cancelPopup();
    }

    OnLoadLocation(range: any) {

        const employee = this.props.employee.item;

        //const center = { lat: employee.latitude, lng: employee.longitude };
        //var config = {
        //    //url: `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${employee.latitude},${employee.longitude}&radius=50&type=restaurant&keyword=cruise&key=${googleApiKey}`,
        //    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' },
        //    crossdomain: true
        //};

        var keyword = ['Non-Profits', 'Not for Profits', 'Thrift Stores', 'Senior Centers', 'Volunteer', 'Habitat Restore', 'Food Pantry', 'Animal Shelter', 'Museum'];


        //var _url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${employee.latitude},${employee.longitude}&radius=5000&keyword=Museum&key=${googleApiKey}`;


        // const result = await fetch(
        //     `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${employee.latitude},${employee.longitude}&radius=5000&keyword=Museum&key=${googleApiKey}`,
        //);
        //this.fetchData();



    }

    componentDidMount() {
        //Here you can set interval which will make requests to API and wait for answers
        //once you get response you do : 
        //setTimeout(this.fetchData, 1000 );
        //if (places.length > 0) {
        //    this.setState({
        //        placeNearby: places
        //    });
        //}
        setTimeout(() => { this.fetchData(1); }, 6000);
       
        //this.fetchData(900);
        var emp = this.state.employee;
    }

    fetchData(range: any) {
        if (range == undefined) {
            range = 900;
        } else {
            range = range * 900;
        }
        
        var emp = this.state.employee;
       
        if (this.props && this.props.employee && this.props.employee.item) {
            const employee = this.props.employee.item;
            const center = new google.maps.LatLng(employee.latitude, employee.longitude);

            map = new google.maps.Map(document.getElementById('map'));

            places = [];
            const bounds = map.getBounds();
            const service = new google.maps.places.PlacesService(map);

            //var keyword = [];
            //if (SelectedItems) {
            //    keyword = SelectedItems;
            //} else {
            //    keyword = ['Non-Profits', 'Not for Profits', 'Thrift Stores', 'Senior Centers', 'Volunteer', 'Habitat Restore', 'Food Pantry', 'Animal Shelter', 'Museum'];
            //}

            SelectedItems.map(name => {
                const request = {
                    bounds: bounds,
                    location: center,
                    radius: range,
                    keyword: name
                };
                service.nearbySearch(request, (results, status) => {
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        for (let i = 0; i < results.length; i++) {
                            var _item = { latitude: '', longitude: '', id: 0, name: '', place_id: '', address: '', phone: '' }
                            _item.id = markers.length + 1;
                            _item.place_id = results[i].place_id;
                            _item.latitude = results[i].geometry.location.lat();
                            _item.longitude = results[i].geometry.location.lng();
                            _item.name = results[i].name;
                            _item.address = results[i].vicinity;
                            if (places.filter(function (e) { return e.place_id === _item.place_id; }).length <= 0) {

                                var MarkerObject = {
                                    position: {
                                        lat: results[i].geometry.location.lat(),
                                        lng: results[i].geometry.location.lng(),
                                    },
                                    id: places.length + 1,
                                    title: results[i].name,
                                    place_id: results[i].place_id
                                }
                                markers.push(MarkerObject);
                                places.push(_item);
                                //var _request = {
                                //    placeId: results[i].place_id,
                                //    fields: ['name', 'formatted_address', 'formatted_phone_number', 'opening_hours', 'business_status','type']
                                //};
                                //service.getDetails(_request, (result, Addstatus) => {
                                //    if (Addstatus == google.maps.places.PlacesServiceStatus.OK) {
                                //        //console.log(result);
                                //        if (result.formatted_address) {
                                //            _item.address = result.formatted_address;
                                //            _item.phone = result.formatted_phone_number;
                                //            places.push(_item);
                                //        } else { places.push(_item); }
                                //    } else { places.push(_item); }
                                //});
                            }
                        }
                        this.render();

                        this.fakeLoad(0);
                    }
                });
            });

            if (places.length > 0) {
                this.setState({
                    placeNearby: places
                });
                this.render();
            }
        }
        //places.map(place => {
        //    var _request = {
        //        placeId: place.place_id,
        //        fields: ['name', 'formatted_address', 'formatted_phone_number']
        //    };
        //    service.getDetails(_request, (results, status) => {
        //        if (status == google.maps.places.PlacesServiceStatus.OK) {
        //            for (let i = 0; i < results.length; i++) {
        //                //_nearByPlaceAddresses
        //            }
        //        }
        //    });
        //});
    };

    createMarker(place) {
        if (!place.geometry || !place.geometry.location) return;

        const marker = new google.maps.Marker({
            map,
            position: place.geometry.location,
            title: place.name
        });

        google.maps.event.addListener(marker, "click", () => {
            infowindow.setContent(place.name || "");
            infowindow.open(map);
        });
    }

    getNearByPlaces(lat: any, lng: any, keyword: any, range: any) {
        if (keyword) {
            var _url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=5000&keyword=${keyword}&key=${googleApiKey}`;
            fetch(_url, {
                mode: 'no-cors',
                method: 'get',
                headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {
                return response => response.json();
            }).then(function (response) {

                //var response => response.json()
                console.log(response);
            }).catch(function (e) {

                console.log(e);
            });
        }
    }

    loadPlaceDetails(placeid: any) {
        debugger;
        if (placeid) {
            var _fields = "name,formatted_address,vicinity,opening_hours/open_now,formatted_phone_number";
            const employee = this.props.employee.item;
            const center = new google.maps.LatLng(employee.latitude, employee.longitude);

            map = new google.maps.Map(document.getElementById('map'));

            const service = new google.maps.places.PlacesService(map);
            var _request = {
                placeId: placeid,
                fields: ['name', 'address_components', 'formatted_address', 'geometry', 'formatted_phone_number', 'opening_hours', 'business_status', 'type']
            };
            service.getDetails(_request, (result, Addstatus) => {
                if (Addstatus == google.maps.places.PlacesServiceStatus.OK) {
                    console.log(result);
                    if (result.formatted_address) {
                        var _address = { placeId: '', phone: "", FullAddress: "", business_status: "", name: "", address_comp: "", geometry: "" };
                        _address.phone = result.formatted_phone_number;
                        _address.FullAddress = result.formatted_address;
                        _address.business_status = result.business_status;
                        _address.placeId = placeid;

                        _address.name = result.name;
                        _address.address_comp = result.address_components;
                        _address.geometry = result.geometry;

                        placeAddresses = _address;
                        // _item.address = result.formatted_address;
                        //  _item.phone = result.formatted_phone_number;
                        // places.push(_item);
                    }
                }
            });
        }
    }

    onEventSave() {
        if (this.props.events) {
            this.props.actions.refreshPages('events', 'eventList', { page: 1, injury: this.props.injury.id, withCalls: true, withSchedule: true, count: 100 });
        }

        this.props.onJobAdd && this.props.onJobAdd();

        this.cancelPopup();
    }

    onSliderChange(range) {
        this.fetchData(range);

        this.props.companiesActions.refreshPages('employeeCompanies', 'companyList', { page: 1, count: 10, range: range, objectId: this.props.employee.id });
    }

    get openedCompanyId(): number | null {
        return this.props.commentsObject.item && this.props.commentsObject.item.objectType == 'company' ? this.props.commentsObject.item.id : null
    }

    get openedPlaceId(): string | null {
        return placeAddresses && placeAddresses.placeId ? placeAddresses.placeId : null;
    }

    get openedCompanyAddress(): { state: string, address: string, city: string, zip: string } | null {
        if (this.props.companies && this.props.companies.items) {
            const companies = this.props.companies.items;
            for (let i = 0; i < companies.length; i++) {
                if (companies[i].id === this.openedCompanyId)
                    return {
                        state: companies[i].state,
                        address: companies[i].address,
                        city: companies[i].city,
                        zip: companies[i].zip
                    }
            }
        }
        return null;
    }

    loadWorksiteEmployees(id: number) {
        if (!this.state.loadedEmployee[id] || this.state.loadedEmployee[id].loaded) {
            request<List<Schedule>>('scheduleList', { company: id, withEmployee: true, injuryNotClosed: true }).then((response: List<Schedule>) => {
                const ids: number[] = [];
                this.setState({
                    loadedEmployee: {
                        ...this.state.loadedEmployee,
                        [id]: {
                            items: response.list.reduce((accumulator: Employee[], item: Schedule) => {
                                if (item.injury && item.injury.employe && ids.indexOf(item.injury.employe.id) === -1) {
                                    ids.push(item.injury.employe.id);
                                    accumulator.push(item.injury.employe);
                                }

                                return accumulator;
                            }, []),
                            loaded: true
                        }
                    }
                })
            });
        }
    }

    fakeLoad(id: number) {
        this.setState({
            loadedEmployee: {
                ...this.state.loadedEmployee,
                [id]: {
                    items: [],
                    loaded: true
                }
            }
        });
    }

    openPopup() {
        this.setState({ addSchedulePopupVisible: true })
    }

    openCallLogPopup() {
        this.setState({ addCallPopupVisible: true })
    }

    cancelPopup() {
        this.setState({
            addSchedulePopupVisible: false,
            addCallPopupVisible: false
        })
    }

    loadMore() {
        this.props.companiesActions.loadMoreItems('employeeCompanies', 'companyList', 10)
    }

    handleChange(event) {

        var isChecked = event.target.checked;
        var item = event.target.value;
        if (isChecked) {
            SelectedItems.push(item);
        }
        else {
            const index = SelectedItems.indexOf(item);
            if (index > -1) { // only splice array when item is found
                SelectedItems.splice(index, 1); // 2nd parameter means remove one item only
            }
        }

        this.fetchData(14);
    }

    createCheckbox = label => (
        <div className="col-sm-6">
            <label>
                <input
                    type="checkbox"
                    value={label}
                    onChange={this.handleChange}
                    defaultChecked={true}
                /> {label}
            </label>
        </div>
    )

    createCheckboxes = () => (
        this.Checkitems.map(this.createCheckbox)
    )

    hideSuccess() {
        setTimeout(() => {
            this.setState({
                success: false
            });
        }, 2000);
    }

    handleCreateCompany(placeAdd: any) {
       
        if (window.confirm('Are you sure you wish to save this external worksite?')) {
            var _zipcode = "";
            var _city = "";
            var state = "";

            for (var j = 0; j < placeAdd.address_comp.length; j++) {
                if (placeAdd.address_comp[j].types[0] == "postal_code") {
                    _zipcode = placeAdd.address_comp[j].long_name;
                }
                if (placeAdd.address_comp[j].types[0].includes("administrative_area_level_1")) {
                    state = placeAdd.address_comp[j].long_name;
                }
                if (placeAdd.address_comp[j].types[0].includes("neighborhood") || placeAdd.address_comp[j].types[0].includes("political")) {
                    _city = placeAdd.address_comp[j].long_name;
                }

            }

            request<Company>('company', {
                id: -1,
                parent: null,
                referral: null,
                companyType: null,
                address: placeAdd.FullAddress.split(',')[0],
                city: _city,
                companyId: null,
                referralId: null,
                companyTypeId: 1,
                email: "",
                extension: "",
                fax: "",
                firstName: "",
                lastName: "",
                latitude: placeAdd.geometry.location.lat(),
                longitude: placeAdd.geometry.location.lng(),
                name: placeAdd.name,
                phone: placeAdd.phone,
                placementFee1: "0.00",
                placementFee2: "0.00",
                placementFee3: "0.00",
                state: state,
                isExternal: true,
                doNotCall: false,
                place_id: placeAdd.placeId,
                zip: _zipcode
            }).then((response: Company) => {

               
                this.setState({
                    success: true
                });
                this.hideSuccess();
            }).catch((error: any) => {
                this.setState({
                    error: error,

                });
                this.hideSuccess();
            });

        }
    }

    render() {
        const { companies, injury, user } = this.props;

        const employee = this.props.employee.item;

        this.setState({ employee: employee});

        const center = { lat: employee.latitude, lng: employee.longitude };

        const openedCompanyId = this.openedCompanyId;

        const openedPlaceId = this.openedPlaceId;

        const placeList = this.state.placeNearby;

        const home: MarkerObject[] = [{
            position: center,
            title: `${employee.firstName || ''} ${employee.lastName || ''}`,
            icon: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
            id: 0
        }];

        markers = companies.items.map((company: Company) => {
            return {
                position: {
                    lat: company.latitude,
                    lng: company.longitude,
                },
                id: company.id,
                title: company.name

            }
        });

        if (places && places.length > 0) {
            var _list = this.state.placeNearby;
            places.map((place: PlaceList) => {
                var MarkerObject = {
                    position: {
                        lat: place.latitude,
                        lng: place.longitude,
                    },
                    id: place.id,
                    title: place.name,
                    place_id: place.place_id
                }
                markers.push(MarkerObject);
            });
        }

        //console.log(places);

        const fullAddress = this.openedCompanyAddress;

        const pagination = companies.pagination;

        return <div className="container-fluid">
            <div className="employee-companies row">
                <div className="col-sm-5">
                    {companies.items.length > 0
                        ? <div>
                            <div className="employee-companies-item__name"><p className="h4">Internal Worksites</p></div>
                            <ul className="employee-companies__list">
                                {companies.items.map((company: Company) => {
                                    let tooltipContent;

                                    const employees = this.state.loadedEmployee[company.id];

                                    if (employees && employees.loaded) {
                                        tooltipContent = employees.items.length > 0
                                            ? employees.items.map((item: Employee) => <div key={item.id}><Link to={`/employe-editor/${item.id}`}>{getUserName(item)}</Link></div>)
                                            : <div>There are not employees</div>
                                    } else {
                                        tooltipContent = <div>Loading employees...</div>;
                                    }

                                    return <Popover placement="right" title={'Employees'} content={tooltipContent} onVisibleChange={(ev) => ev && this.loadWorksiteEmployees(company.id)} key={company.id}>
                                        <li className={`employee-companies-item ${openedCompanyId === company.id ? 'is-active' : ''}`} onClick={e => this.markerClick(company)}>
                                            {user && user.role === UserRole.Admin &&
                                                <Link to={'/company-editor/' + company.id}
                                                    className="fa fa-pencil employee-companies-item__edit"></Link>}
                                            <div className="employee-companies-item__name">
                                                {company.name} {company.distance !== undefined ? <span
                                                    className="employee-companies-item__distance">({company.distance.toFixed(2)} mi)</span> : ''}
                                            </div>
                                            <div className="employee-companies-item__address">
                                                <i className="fa fa-map-marker"></i>{`${company.address || ''}, ${company.city || ''}, ${company.state || ''}, ${company.zip}`}
                                            </div>
                                            <div className="employee-companies-item__address">
                                                {(company.firstName || company.lastName) && <React.Fragment><i
                                                    className="fa fa-user"></i>{`${company.firstName || ''} ${company.lastName || ''} `}
                                                </React.Fragment>}
                                                {(company.phone) &&
                                                    <React.Fragment><i className="fa fa-phone"></i>{company.phone}
                                                    </React.Fragment>}
                                            </div>
                                            {company.doNotCall &&
                                                <div className="doNotCall">
                                                    <i className="fa fa-phone-slash"></i><span>Do not call </span>
                                                </div>
                                            }


                                            {injury.injuryStatus !== 5 && openedCompanyId === company.id &&
                                                <div className="clearfix">
                                                    <div className="pull-left"><a onClick={this.openPopup}>Add schedule</a>
                                                    </div>
                                                    <div className="pull-right"><a onClick={this.openCallLogPopup}>Add call</a>
                                                    </div>
                                                </div>}
                                        </li>
                                    </Popover>
                                }
                                )}
                            </ul>
                            {pagination && (pagination.total - pagination.offset > 10) && <div className="flex-end">
                                <Button type="button" className="btn btn-primary mt10" onClick={this.loadMore}>Load More</Button>
                            </div>}
                        </div>
                        : <div className="not-found-title">Companies not found</div>
                    }
                </div>
                <div className="employee-companies__map col-sm-7">
                    <div className="row">
                        <Map id="maps" center={center}
                            markers={home.concat(markers)}
                            zoom={13}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            onMarkerClick={this.markerClick}
                            openWindowId={openedCompanyId}
                            renderMarker={(marker) => <div>
                                <div>{marker.title}</div>
                                {injury.injuryStatus !== 5 && !marker.place_id && <div className="clearfix">
                                    <div style={{ marginTop: '5px' }}><a onClick={this.openPopup}>Add schedule</a></div>
                                    <div style={{ marginTop: '5px' }}><a onClick={this.openCallLogPopup}>Add call</a></div>
                                </div>}
                            </div>}
                        />
                    </div>
                    <div className="row">
                        <Slider marks={this.sliderMarks} min={5} max={125} step={1} defaultValue={5} onAfterChange={this.onSliderChange} />
                    </div>
                    {fullAddress && <div className="row">
                        <div className="pull-right">
                            <GoogleMapButton state={fullAddress.state} address={fullAddress.address} city={fullAddress.city} zip={fullAddress.zip} />
                        </div>
                    </div>}



                    {places && places.length > 0 ?
                        <div className="row">
                            <div className="employee-companies-item__name"><p className="h4">External Worksites</p></div>
                            <div className="row">{this.createCheckboxes()}</div>


                            <div className="">
                                <ul className="employee-companies__list">
                                    {places.map((company) => {
                                        let tooltipContent;
                                        //tooltipContent = <p>It's a near by place</p>;
                                        return <div title={'External Worksite Details'} onMouseEnter={(ev) => ev && this.loadPlaceDetails(company.place_id)} key={company.id}>
                                            <li className={`employee-companies-item ${openedPlaceId === company.place_id ? 'is-active' : ''}`} onClick={e => this.markerClick(company)} >
                                                <div className="employee-companies-item__address">
                                                    <i className="fa fa-map-marker"></i>{company.address}
                                                </div>
                                                <div className="employee-companies-item__address">
                                                    <i className="fa fa-user"></i>{company.name}
                                                </div>

                                                {placeAddresses && openedPlaceId === company.place_id &&
                                                    <div className="clearfix">
                                                        <div className="employee-companies-item__address">
                                                            <i className="fa fa-phone"></i>{placeAddresses.phone}
                                                        </div>
                                                        <div className="employee-companies-item__address">
                                                            <i className="fa fa-folder-open"></i>{placeAddresses.business_status}
                                                        </div>

                                                        <div className="employee-companies-item__address">
                                                            <i className="fa fa-map-marker"></i>{placeAddresses.FullAddress}
                                                    </div>
                                                    <React.Fragment>
                                                        {this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
                                                        {this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
                                                    </React.Fragment>
                                                    </div>

                                                }<div className="clearfix">
                                                    <div className="employee-companies-item__address pull-right">
                                                        <a onClick={e => this.handleCreateCompany(placeAddresses)}>Save this location</a>
                                                    </div> </div>
                                            </li>

                                        </div>
                                    }
                                    )}
                                </ul>
                                
                            </div>
                        </div> : <div className="row"></div>
                    }

                </div>
            </div>
            
            {openedCompanyId && this.state.addSchedulePopupVisible && <AddEventPopup
                companies={companies}
                companyId={openedCompanyId}
                injury={injury}
                onSave={this.onEventSave}
                onCancel={this.cancelPopup}
            />}
            {openedCompanyId && <Modal
                title={'Add New Call'}
                onCancel={this.cancelPopup}
                width={600}
                footer={null}
                visible={this.state.addCallPopupVisible}
            >
                <CallLogEditor companyId={openedCompanyId} callLogId={-1} onCancel={this.cancelPopup} injury={injury} onSave={this.onCallLogSave} />
            </Modal>}
        </div>

    }
}

export default connect(
    (state: ApplicationState, ownProps?: OwnProps) => {
        if (!ownProps) { return {}; }

        return {
            companies: state.employeeCompanies,
            employee: state.employe,
            events: state.events[ownProps.injury.id],
            commentsObject: state.employeeCommentsObject,
            company: state.company
        }
    },
    (dispatch, ownProps?: OwnProps) => {
        if (!ownProps) { return {}; }

        return {
            actions: bindActionCreators(getEventActionCreators(ownProps.injury.id), dispatch),
            commentsObjectAction: bindActionCreators(EmployeeCommentsObjectState.actionCreators, dispatch),
            companiesActions: bindActionCreators(ItemsState.getActionCreators(), dispatch),
            itemActions: bindActionCreators(PageState.getActionCreators(), dispatch)
        }
    }
)(EmployeeCompanyList as any);