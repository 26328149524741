import * as React from "react";

import Schedule from "@app/objects/Schedule";

import {Injury} from "@app/objects/Injury";
import {connect} from "react-redux";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {ApplicationState} from "@app/store";


import {Loading} from "@app/components/UI/Loading";


import * as PagesState from "@app/store/ItemList";

import {Template} from "@app/objects/Template";

import * as ItemsState from "@common/react/store/ItemList";

interface OwnProps {
	injury: Injury;
	schedule: Schedule;
}

interface ReduxProps {
	templates: PagesState.ItemsState<Template>
}

interface Actions {
	templatesActions: ActionCreatorsMapObject;
}

type Props = OwnProps & ReduxProps & Actions;

interface ComponentState {
	isLoading: boolean;
	templateId: number;
}

class InjuryTemplateDocument extends React.Component<Props, ComponentState> {
	constructor(props){
		super(props);

		this.state = {
			templateId: -1,
			isLoading: false,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillMount() {
		this.props.templatesActions.reqPages('templates', 'templateList', 'template', {page: 1, count: 100});
	}

	handleChange(event) {
		const target = event.target as HTMLInputElement;
		
		this.setState({
			...this.state,
			[target.name as string]: target.value}
		);
	}
	
	render() {
		const {injury} = this.props;

		if(injury) {
			const templates = this.props.templates.items;
			
			const {templateId} = this.state;
			
			const scheduleId = this.props.schedule.id;
			
			const isAllowed = scheduleId > 0 && templateId > 0;
			
			return <div className="download-injury-document container-fluid">
				<div className="row">
					<div className="col-sm-6 form-group">
						<label htmlFor="templateId">Template*</label>
						<div className="input-group">
							<select className="form-control" id="templateId" name="templateId" value={this.state.templateId} onChange={this.handleChange}>
								<option disabled value={-1}>Select template</option>
								{templates && templates.sort((a, b) => a.name.localeCompare(b.name)).map((template: Template) => (
									<option value={template.id} key={template.id}>{template.name}</option>))
								}
							</select>
							<span className="input-group-btn">
								<a target="_blank" href={isAllowed ? `/generate?injuryId=${injury.id}&templateId=${templateId}&jobId=${scheduleId}`  : ''} className={'btn btn-sm btn-primary btn-with-input ' + (isAllowed ? '' : 'disabled')} download={true}>Download</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		}

		return <Loading/>
	}
}

export default connect(
	(state: ApplicationState, ownProps?: OwnProps) => {
		if (!ownProps || ownProps.injury.id <= 0) { return {}; }

		return {
			templates: state.templates
		};
	},
	(dispatch, ownProps?: OwnProps) => {
		if (!ownProps || ownProps.injury.id <= 0) { return {}; }

		return {
			templatesActions: bindActionCreators(ItemsState.getActionCreators(), dispatch)
		}
	}
)(InjuryTemplateDocument);