import * as React from 'react';
import loadable from '@loadable/component'
import {loadableDelay, params} from "@app/loadableSettings";

const InjurySchedule = loadable(() => loadableDelay(import( /* webpackChunkName: "InjuryScheduleTab" */ "@app/components/Pages/Employees/InjurySchedule/InjurySchedule")), params)

import {Injury} from "@app/objects/Injury";
import {Field, FieldProps, Formik, FormikProps} from "formik";
import Button from "@common/react/components/Forms/Button";
import {User, UserRole} from "@app/objects/User";
import {Link} from "react-router-dom";
import EmployeeCompanyList from "@app/components/Pages/Employees/EmployeeCompanyList";
import {stateZoneNames} from "@common/react/components/Forms/StateTimeZoneSelect/StateTimeZoneSelect";
import InjuryTemplateDocument from "@app/components/Pages/Employees/InjuryTemplateDocument";
import InjuryContacts from "@app/components/Pages/Employees/InjuryContacts";
import Tabs from "antd/lib/tabs";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {getSelectedInjuryJobsActionCreators} from "@app/store/InjuryJobs";

import * as PagesState from "@app/store/ItemList";
import Schedule, {DayOfWeek} from "@app/objects/Schedule";
import {Employee} from "@app/objects/Employee";

import Select from "antd/lib/select";
import { request } from "@app/components/Api";

import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';
import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";
import * as moment from "moment";
import {CompanyCallLogOwnProps} from "@app/components/Pages/Companies/CompanyCallLog";
import CompanyCallLog from "@app/components/Pages/Companies/CompanyCallLog";
import {getSelectedInjuryCallLogsActionCreators} from "@app/store/InjuryCallLogs";
import {deleteConfirmation} from "@common/react/components/Modal/Modal";
import {Modal} from "antd";
import {CompanyView} from "@app/components/Pages/Employees/InjuryForm/CompanyView";

const timepickerProps = {
	use12Hours: true,
	format: "h:mm a"
};

const Option = Select.Option;

interface ComponentProps {
	item: Injury;
	user: User;
	employee: Employee;
	isEditable: boolean;
}

interface ReduxProps {
	jobs: PagesState.ItemsState<Schedule>;
}

interface Actions {
	injuryJobsActions: ActionCreatorsMapObject;
}

interface ComponentState {
	selectedJob: Schedule | null;
	isLoading: boolean;
	callLogRefreshCounter: number;
	success: boolean;
	error: string | null;
	refreshSchedule: boolean;
	showCompany: boolean;
}

interface FormValues extends Schedule{
	time: string | null;
	reportDay: number;
}


type Props = ReduxProps & ComponentProps & Actions;

const TabPane = Tabs.TabPane;

const ConnectedCallLog = connect<any, any, any>((state: ApplicationState, ownProps: CompanyCallLogOwnProps) =>({
		...state.injuryCallLogs[ownProps.injury ? ownProps.injury.id : -1]
	}),
	(dispatch, ownProps?: CompanyCallLogOwnProps) => {
		if (!ownProps || !ownProps.injury || ownProps.injury.id <= 0) { return {}; }

		return {
			...bindActionCreators(getSelectedInjuryCallLogsActionCreators(ownProps.injury.id), dispatch)
		}
	}
)(CompanyCallLog as any);

class NonForProfitTab extends React.Component<Props, ComponentState> {
	constructor(props: Props) {
		super(props);
		
		this.state = {
			selectedJob: null,
			isLoading: false,
			callLogRefreshCounter: 0,
			success: false,
			error: null,
			refreshSchedule: false,
			showCompany: false
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onJobAdd = this.onJobAdd.bind(this);
		this.onCallAdd = this.onCallAdd.bind(this);
		this.handleClearSchedule = this.handleClearSchedule.bind(this);
        this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
		this.handleOpenPopup = this.handleOpenPopup.bind(this);
		this.handleClosePopup = this.handleClosePopup.bind(this);
	}
	
	componentWillMount() {
		this.props.injuryJobsActions.reqPages('injuryJobs', 'scheduleList', 'schedule', {page: 1, count: 100 , objectId: this.props.item.id}).then(() => {
			if(this.props.jobs.items.length > 0)
				this.setState({
					selectedJob: this.props.jobs.items[0]
				})
		});
	}
	
	onJobAdd() {
		this.props.injuryJobsActions.refreshPages('injuryJobs', 'scheduleList', {page: 1, count: 100 , objectId: this.props.item.id}).then(() => {
			if(this.props.jobs.items.length > 0)
				this.setState({
					selectedJob: this.props.jobs.items[this.props.jobs.items.length - 1]
				})
		});
	}
	
	onCallAdd(companyId: number) {
		const {selectedJob, callLogRefreshCounter} = this.state;
		
		if(selectedJob && selectedJob.companyId === companyId) {
			this.setState({
				callLogRefreshCounter: callLogRefreshCounter + 1
			});
		}
	}

	handleChange(value: string, option: any) {
		if(option) {
			const item: Schedule = option.props.item;

			this.setState({
				selectedJob: item
			});
		}
	}
	
	handleClearSchedule() {
		const selectedJob = this.state.selectedJob;
		
		if (selectedJob) {
			deleteConfirmation( () =>
				request('clearSchedule', {id: selectedJob.id}).then( () => {
						this.props.injuryJobsActions.refreshPages('injuryJobs', 'scheduleList', {
							page: 1,
							count: 100,
							objectId: this.props.item.id
						}).then(() => {
							this.setState({
								selectedJob: this.props.jobs.items.length > 0 ? this.props.jobs.items[0] : null,
								refreshSchedule: !this.state.refreshSchedule
							});
						})
					}
				),
				'Are you sure that you want to delete this schedule?'
			)
		}
    }

    handleDeleteSchedule() {
        const selectedJob = this.state.selectedJob;

        if (selectedJob) {
            deleteConfirmation(() =>
                request('deleteSchedule', { id: selectedJob.id }).then(() => {
                    this.props.injuryJobsActions.refreshPages('injuryJobs', 'scheduleList', {
                        page: 1,
                        count: 100,
                        objectId: this.props.item.id
                    }).then(() => {
                        this.setState({
                            selectedJob: this.props.jobs.items.length > 0 ? this.props.jobs.items[0] : null,
                            refreshSchedule: !this.state.refreshSchedule
                        });
                    })
                }
                ),
                'Are you sure that you want to delete this schedule?'
            )
        }
    }
	
	
	handleOpenPopup(event) {
		event.stopPropagation();
		
		if (this.state.selectedJob) {
			this.setState({ showCompany: true });
		}
	}
	
	handleClosePopup() {
		this.setState( { showCompany: false });
	}
	
	handleSubmit(values: FormValues) {
		let reportTime;
		
		if(values.reportTime) {
			reportTime = moment.utc(values.reportTime);
			
			if (values.time) {

				reportTime.hour(+values.time.toString().split(':')[0]);
				reportTime.minute(+values.time.toString().split(':')[1]);
			}
		}
		
		
		let data = {
			nonProfitAdditionalInstructions: values.nonProfitAdditionalInstructions || 0,
			jobTask: values.jobTask || '',
			physicalRequirements: values.physicalRequirements || '',
			timeRequirements: values.timeRequirements || '',
			reportTo: values.reportTo || '',
			title: values.title || '',
			placementTime: values.placementTime || 0,
			startTime: values.startTime || 0,
			reportDay: values.reportDay || 0,
			reportTime: reportTime !== undefined ? +reportTime : values.reportTime,
			actualStartTime: values.actualStartTime || 0
		};
		
		const item = {...this.state.selectedJob, ...data, company: null, workDays: null};

		this.setState({isLoading: true});
		
		request('scheduleNonProfitDetails', item).then((result) => {
			this.setState(prevState => ({
				isLoading: false,
				success: true,
				selectedJob: {
					...prevState.selectedJob,
					...(result as any)
				}
			}));
			
			setTimeout(() => {
				this.setState({
					success: false
				})
			}, 3000);

			this.props.injuryJobsActions.refreshPages('injuryJobs', 'scheduleList', {page: 1, count: 100 , objectId: this.props.item.id});
		}).catch((error: string) => {
			this.setState({
				isLoading: false,
				error: error
			});
		});
	}
	
	render() {
		const {item, user, employee, isEditable} = this.props;
		
		const {selectedJob, callLogRefreshCounter, refreshSchedule, showCompany} = this.state;

		const isNotExternal: boolean = user !== null && user.role !== UserRole.External;

		const jobs = this.props.jobs ? this.props.jobs.items : [];
		
		return <div className="injury-nonProfit-tabs">
					<div className="clearfix">
						<div className="pull-right">
							<Select
								showSearch
								style={{ width: 250 }}
								placeholder="Select a job"
								allowClear={true}
								value={selectedJob ? selectedJob.id.toString() : undefined}
								onChange={this.handleChange}>
								{jobs.map(schedule =>
									<Option {...{item: schedule}} key={schedule.id + ''} value={schedule.id.toString()}>
										{selectedJob && schedule.id === selectedJob.id && schedule.company && <div className="pull-right">
											<a onClick={this.handleOpenPopup}><i className="fa fa-eye"></i></a>
										</div>}
										{schedule.company && schedule.company.name}
									</Option>
								)}
							</Select>
						</div>
						{this.state.selectedJob && <Modal
							title={selectedJob && selectedJob.company ? selectedJob.company.name : ''}
							visible={showCompany}
							onCancel={this.handleClosePopup}
							footer={null}
							width={650}
						>
							<CompanyView id={this.state.selectedJob.companyId} />
						</Modal>}
					</div>
					<Tabs>
						<TabPane tab="CallLog" key="call-log" >
							<ConnectedCallLog injury={item} companyId={0} refreshCounter={callLogRefreshCounter} storeName="injuryCallLogs" withoutHeadline={true} worksiteTab={true} selectedCompanyName={selectedJob && selectedJob.company ? selectedJob.company.name : ''}/>
						</TabPane>
						{isNotExternal &&
						<TabPane tab="Worksite" key="non-for-profit">
							{user && user.role === UserRole.Admin && <div className="form-group text-right">
								<Link to="/company-editor/-1?nonForProfit=1" className="btn btn-primary">Add Site</Link>
							</div>}
							<EmployeeCompanyList injury={item} user={user} onJobAdd={this.onJobAdd} onCallAdd={this.onCallAdd}/>
						</TabPane>
						}
						<TabPane tab="Worksite Details"  key="nonProfitDetails">
							{selectedJob 
								? isEditable 
									? <Formik
										initialValues={{
											...selectedJob,
											nonProfitAdditionalInstructions: selectedJob.nonProfitAdditionalInstructions || '',
											jobTask: selectedJob.jobTask || '',
											physicalRequirements: selectedJob.physicalRequirements || '',
											timeRequirements: selectedJob.timeRequirements || '',
											reportTo: selectedJob.reportTo || '',
											title: selectedJob.title || '',
											placementTime: selectedJob.placementTime || 0,
											reportTime: selectedJob.reportTime || null,
											time: selectedJob.reportTime ? moment.utc(selectedJob.reportTime).format('HH:mm:ss') : null,
											reportDay: selectedJob.reportDay || (selectedJob.reportTime ? new Date(selectedJob.reportTime).getDay() : 0),
											startTime: selectedJob.startTime || 0,
											actualStartTime: selectedJob.actualStartTime || 0
										}}
										onSubmit={this.handleSubmit}
										enableReinitialize={true}
										render={(formikBag: FormikProps<FormValues>) => {
											return <div id={'non-profit-injury-form' + item.id}>

												<div className="row">
													<div className="form-group col-sm-6">
														<label>Placement Date</label>
														<div>
															<Datepicker value={formikBag.values.placementTime || null} format="MM/DD/YY" onChange={date => formikBag.setFieldValue('placementTime', date)} />
														</div>
													</div>
													<div className="col-sm-6">
														<label>Actual Start Day</label>
														<Datepicker value={formikBag.values.actualStartTime || null} format="MM/DD/YY" onChange={date => formikBag.setFieldValue('actualStartTime', date)}/>
													</div>
												</div>

												<div className="row">
													<div className="form-group col-sm-4">
														<label>Report Date</label>
														<div>
															<Datepicker
																value={formikBag.values.reportTime || null}
																format="MM/DD/YY"
																onChange={date => {
																	formikBag.setFieldValue('reportTime', date);
																	if (date) formikBag.setFieldValue('reportDay', new Date(date).getDay());
																	if(date === null) {
																		formikBag.setFieldValue('time', null);
																	}
																}}
															/>
														</div>
													</div>
													<div className="form-group col-sm-4">
														<label>Report Time</label>
														<div className="timepicker-component">
															<Field
																name="time"
																render={({field, form}: FieldProps<FormValues>) => (
																	<Timepicker 
																		value={field.value} 
																		onChange={time => formikBag.setFieldValue('time', time)} 
																		utc={true}
																		antdProps={{
																			...timepickerProps,
																			disabled: formikBag.values.reportTime === null
																		}}
																	/>
																)}
															/>
														</div>
													</div>
													<div className="col-sm-4">
														<label>Report Day</label>
														<Field
															name="reportDay"
															render={({field, form}: FieldProps<FormValues>) => (
																<select className="form-control" disabled={true} id={`reportDay`} value={field.value} {...field}>
																	<option disabled value={-1}>Select start date</option>
																	<option value={DayOfWeek.Sunday}>Sunday</option>
																	<option value={DayOfWeek.Monday}>Monday</option>
																	<option value={DayOfWeek.Tuesday}>Tuesday</option>
																	<option value={DayOfWeek.Wednesday}>Wednesday</option>
																	<option value={DayOfWeek.Thursday}>Thursday</option>
																	<option value={DayOfWeek.Friday}>Friday</option>
																	<option value={DayOfWeek.Saturday}>Sunday</option>
																</select>
															)}
														/>
													</div>
												</div>

												<Field name="nonProfitAdditionalInstructions"
												       render={({field, form}: FieldProps<FormValues>) => (
													       <div className={`form-group`}>
														       <label
															       htmlFor={`nonProfitAdditionalInstructions${item.id}`}>Worksite
															       Additional Instructions</label>
														       <textarea className="form-control" id={`nonProfitAdditionalInstructions${item.id}`} {...field}/>
													       </div>
												       )}
												/>
												<Field name="jobTask"
												       render={({field, form}: FieldProps<FormValues>) => (
													       <div className={`form-group`}>
														       <label htmlFor={`jobTask${item.id}`}>Job Task</label>
														       <textarea className="form-control" id={`jobTask${item.id}`} {...field}/>
													       </div>
												       )}
												/>
												<Field name="physicalRequirements"
												       render={({field, form}: FieldProps<FormValues>) => (
													       <div className={`form-group`}>
														       <label htmlFor={`physicalRequirements${item.id}`}>Physical Requirements</label>
														       <textarea className="form-control" id={`physicalRequirements${item.id}`} {...field}/>
													       </div>
												       )}
												/>
												<Field name="timeRequirements"
												       render={({field, form}: FieldProps<FormValues>) => (
													       <div className={`form-group`}>
														       <label htmlFor={`timeRequirements${item.id}`}>Time Requirements</label>
														       <textarea className="form-control" id={`timeRequirements${item.id}`}  {...field}/>
													       </div>
												       )}
												/>
												<div className="row">
													<Field
														name="reportTo"
														render={({field, form}: FieldProps<Schedule>) => (
															<div className={`form-group col-sm-6`}>
																<label htmlFor="reportTo">Report To</label>
																<input className="form-control" type="text" id="reportTo" {...field} />
															</div>
														)}
													/>
													<Field
														name="title"
														render={({field, form}: FieldProps<FormValues>) => (
															<div className={`form-group col-sm-6`}>
																<label htmlFor="title">Title</label>
																<input className="form-control" type="text" id="title" {...field} />
															</div>
														)}
													/>
												</div>
												<div className="text-center form-group">
													<Button isLoading={this.state.isLoading} className="btn btn-primary"
													        onClick={e => {
														        e.preventDefault();
														        e.stopPropagation();
														        formikBag.handleSubmit()
													        }}>Save</Button>
												</div>
												<React.Fragment>
													{this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
													{this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
												</React.Fragment>
											</div>
										}}
									/>
									: <React.Fragment>
									<div className={`row`}>
										<div className={`form-group col-sm-12`}>
											<label>Trans Issue Type</label>
											<div>
												{item.transIssueType || '-'}
											</div>
										</div>
									</div>
									<div className={`row`}>
										<div className={`form-group col-sm-12`}>
											<label>Worksite Additional Instructions</label>
											<div>
												{item.nonProfitAdditionalInstructions || '-'}
											</div>
										</div>
									</div>
									<div className={`row`}>
										<div className={`form-group col-sm-12`}>
											<label>Job Task</label>
											<div>
												{item.jobTask || '-'}
											</div>
										</div>
									</div>
									<div className={`row`}>
										<div className={`form-group col-sm-12`}>
											<label>Physical Requirements</label>
											<div>
												{item.physicalRequirements || '-'}
											</div>
										</div>
									</div>
									<div className={`row`}>
										<div className={`form-group col-sm-12`}>
											<label>Time Requirements</label>
											<div>
												{item.timeRequirements || '-'}
											</div>
										</div>
									</div>
									<div className="row">
										<div className={`form-group col-sm-6`}>
											<label>Report To</label>
											<div>
												{item.reportTo || '-'}
											</div>
										</div>
										<div className={`form-group col-sm-6`}>
											<label>Title</label>
											<div>
												{item.title || "-"}
											</div>
										</div>
									</div>

								</React.Fragment>
								: <div className="not-found-title">Job was not selected</div>
							}
						</TabPane>
						{isNotExternal &&
							<TabPane tab="Schedule" key="schedule">
								<div className="pull-left mb10">
                                <button type="button" className="btn btn-default" onClick={this.handleClearSchedule}>Clear Schedule</button>
                                <button type="button" className="btn btn-default" onClick={this.handleDeleteSchedule}>Delete Schedule And Worksite</button>
								</div>
								<div className="text-right pull-right">
									<div className="timezone-scheduler-label"><i className="fa fa-clock-o"/>Timezone: {employee && (stateZoneNames[employee.timeZone] || 'Not set')}</div>
								</div>
								<div className='row'>
									<div className="col-sm-12">
                            <InjurySchedule injury={item} refresh={refreshSchedule} scheduleId={selectedJob ? selectedJob.id : undefined} schedule={selectedJob}/>
									</div>
								</div>
							</TabPane>
						}
						<TabPane tab="Alerts" key="contacts" >
							{selectedJob
								? <InjuryContacts injury={item} employee={employee} schedule={ selectedJob}/>
								: <div className="not-found-title">Job was not selected</div>
							}
						</TabPane>
						<TabPane tab="Doc Creator" key="template-documents">
							{selectedJob
								? <InjuryTemplateDocument injury={item} schedule={selectedJob}/>
								: <div className="not-found-title">Job was not selected</div>
							}
						</TabPane>
					</Tabs>
		</div>
	}
}

export default connect<ReduxProps, Actions, ComponentProps>(
	(state: ApplicationState, ownProps?: ComponentProps) => {
		return {
			jobs: state.injuryJobs[ownProps!.item!.id] as PagesState.ItemsState<Schedule>
		};
	},
	(dispatch, ownProps?: ComponentProps) => {
		return {
			injuryJobsActions: bindActionCreators(getSelectedInjuryJobsActionCreators(ownProps!.item!.id), dispatch),
		}
	}
)(NonForProfitTab);
