import {SpecialCompanyType} from "@app/objects/CompanyType";
import * as React from 'react';

import {connect} from 'react-redux';
import {ApplicationState} from '@app/store/index';

import * as PagesState from "@app/store/ItemList";

import {ExtendableItemEditor} from "@app/components/Pages/ItemEditor";

import * as Yup from 'yup';

import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';

import Button from "@common/react/components/Forms/Button";
import {Loading} from "@app/components/UI/Loading";
import {Employee, EmployeeFormValues, WorkerComp} from "@app/objects/Employee";

import {PayrollPeriod} from "@app/objects/PayrollPeriod";

import Tabs from 'antd/lib/tabs';

import Icon from 'antd/lib/icon';

import message from "antd/lib/message";

import {State} from "@common/react/components/Forms/State/State";
import {Injury, InjuryPendingReason, InjuryStatus, InjuryType} from "@app/objects/Injury";
import CommentTree from "@app/components/Forms/Comment/CommentTree";

import MaskedInput from 'react-text-mask';
import InjuryForm from "@app/components/Pages/Employees/InjuryForm/InjuryForm";


import {request} from '@app/components/Api';
import {List} from "@common/typescript/objects/List";
import {AttorneyType} from "@app/objects/Attorney";
import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";
import ScrollToError from "@common/react/components/Forms/ScrollToError";
import {FormikList} from "@common/react/components/Forms/FormikList/FormikList";
import Occupation from "@app/objects/Occupation";
import {getUserNameWithEmail, phoneFormat, phoneMask, phoneReplace} from "@app/components/Utils/Utils";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";

import * as PageState from "@common/react/store/Item";
import * as ItemsState from '@common/react/store/ItemList';
import * as EmployeeCommentsObjectState from "@app/store/EmployeeCommentsObject";

import EmployeeEditorView from "@app/components/Pages/Employees/EmployeeEditorView"

import {User, UserRole} from "@app/objects/User";
import {StateTimeZoneSelect} from "@common/react/components/Forms/StateTimeZoneSelect/StateTimeZoneSelect";
import {DayOfWeek} from "@app/objects/Schedule";
import {GoogleMapButton} from "@app/components/UI/GoogleMapButton/GoogleMapButton";
import {parseQuery} from "@common/typescript/utils/url";
import {notNullValidator, phoneValidator, simpleStringValidator} from "@common/react/utils/validationHelpers";
import {ZipAutocomplete} from "@common/react/components/Forms/ZipAutocomplete/ZipAutocomplete";
import {FormikInput} from "@common/react/components/Forms/FormikInput/FormikInput";

﻿

const validationSchema = Yup.object().shape({
	firstName: simpleStringValidator,
	lastName: simpleStringValidator,
	email: Yup.string().email(),
	state: simpleStringValidator,
	address: simpleStringValidator,
	city: simpleStringValidator,
	zip: simpleStringValidator,
	companyId: notNullValidator,
	referralId: notNullValidator,
	referralContactId:  Yup.mixed().test('is-selected', 'Required field!', value => value !== -1),
	phone: phoneValidator,
	mobile: phoneValidator,
});


const TabPane = Tabs.TabPane;

interface ReduxProps {
	injuryTypes: PagesState.ItemsState<InjuryType>,
	injuryPendingReasons: PagesState.ItemsState<InjuryPendingReason>,
	occupations: PagesState.ItemsState<Occupation>,
	commentsObject: EmployeeCommentsObjectState.EmployeeCommentsObjectState,
	user: User | null
}

interface Actions {
	commentsObjectAction: EmployeeCommentsObjectState.EmployeeCommentsObjectCreators
}


class EmployeeEditor extends ExtendableItemEditor<Employee, ReduxProps & {history: History} & Actions> {
	companyName: string;
	companyNameCurrentInputValue: string;
	form = React.createRef<Formik<EmployeeFormValues>>();
	
	constructor(props: any) {
		super(props);

		this.type = 'employe';
		this.path = 'getEmploye';
		this.backPath = '/employees';
		this.itemsStore = 'employees';
		this.itemsPath  = 'employeList';
		this.defaultItem = {id: -1, companyId: null, injuries: {count: 0, list: [{ id: -1}], execution: 0, offset: 0}, languages: {count: 0, list: [], execution: 0, offset: 0}};
		this.addInjury = this.addInjury.bind(this);
		this.updateInjury = this.updateInjury.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onChange = this.onChange.bind(this);
		this.companyOnSelect = this.companyOnSelect.bind(this);
		
		this.companyName = this.props.item
			? this.props.item.companyName
			: '';
		
		this.companyNameCurrentInputValue = this.companyName;
		
		let vccCase = parseQuery(this.props.history.location.search)['case'];

		let activeKey = 'general-tab';
		
		if (vccCase == 'new') {
			activeKey = 'general-tab';
			this.props.history.replace('/employe-editor/' + (+this.props.match.params.id));
		} else if (vccCase && this.props.item && this.props.item.id > 0) {
			const injury = this.props.item.injuries.list.find(injury => injury.vccCase == vccCase);
			if(injury) {
				activeKey = 'injury-' + injury.id;
				this.props.commentsObjectAction.setCommentsObject({id: injury.id, objectType: 'injury', caption: 'Case'});
			}
		}
		
		this.state = {
			isLoading: false,
			success: false,
			error: null,
			next: false,
			activeKey: activeKey,
			contacts: [],
			contactsLoaded: false,
			referralId: 0
		}
	}

	componentWillMount() {
		let itemPathOrId: number = +this.props.match.params.id;

		super.componentWillMount();
		
		this.props.pagesActions.reqPages('injuryTypes', 'injuryTypeList', 'injuryType', { page: 1, text: '', count: 100 });
		this.props.pagesActions.reqPages('injuryPendingReasons', 'injuryPendingReasonList', 'injuryPendingReason', { page: 1, text: '', count: 100 });
		this.props.pagesActions.reqPages('occupations', 'occupationList', 'occupation', { page: 1, text: '', count: 100 });
		
		if(itemPathOrId > 0) {
			this.props.pagesActions.refreshPages('employeeCompanies', 'companyList', { page: 1, count: 10, range: 5, objectId: itemPathOrId});
		}
	}
	
	componentDidMount() {
		const vccCase = this.props.location.search;

		if (vccCase && this.props.item) {
			const caseId = parseQuery(this.props.location.search)['case'];
			const injury = this.props.item.injuries.list.find(injury => injury.vccCase == caseId);

			injury && this.props.commentsObjectAction.setCommentsObject({ id: injury.id, objectType: 'injury', caption: 'Case' });
		}
	}
	
	componentWillReceiveProps(nextProps: any, nextState: any) {
		let itemPathOrId: number = +this.props.match.params.id;
		
		if (+nextProps.match.params.id !== itemPathOrId && itemPathOrId !== -1) {
			let itemPathOrId: number = +nextProps.match.params.id;

			this.props.itemActions.loadItem(this.type, this.path, itemPathOrId, this.defaultItem, this.additionalParams);

			//this.props.itemActions.loadItem(this.type, this.path, itemPathOrId, this.defaultItem, this.additionalParams);

			if(itemPathOrId > 0) {
				this.props.pagesActions.refreshPages('employeeCompanies', 'companyList', { page: 1, count: 10, range: 5, objectId: itemPathOrId});
			}
			
			this.setState({activeKey: 'general-tab'})
		}

		const vccCase = parseQuery(nextProps.location.search)['case'];
		
		if(nextProps.item && nextProps.item !== this.props.item && vccCase && +itemPathOrId === nextProps.item.id) {
			const injury = nextProps.item.injuries.list.find(injury => injury.vccCase == vccCase);
			const commentObject: EmployeeCommentsObjectState.EmployeeCommentsObject | null = this.props.location.search ? this.props.commentsObject.item : null;

			if (injury && (!commentObject || (commentObject && commentObject.id !== injury.id))) {
				this.props.commentsObjectAction.setCommentsObject({ id: injury.id, objectType: 'injury', caption: 'Case' });
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let itemPathOrId: number = +this.props.match.params.id;
		let vccCase = parseQuery(this.props.history.location.search)['case'];
		
		if (this.props.item && this.props.item.id > 0) {
			
			if(vccCase) {
				vccCase = vccCase.replace('&emails', '').replace('&calls', '');
			}

			const injury = this.props.item.injuries.list.find(injury => !!injury.vccCase && injury.vccCase == vccCase);

			if (injury) {
				let activeKey = 'injury-' + injury.id;

				if (prevState.activeKey !== activeKey) {
					this.setState({
						activeKey: activeKey
					});
				}
			}
		}
		
		if (this.props.item && this.props.item.id === itemPathOrId) {
			if (this.props.item.referralId && !this.state.contactsLoaded) {
				this.setState({
					contactsLoaded: true
				});
				this.loadContacts(this.props.item.referralId);
			}

			if (this.state.referralId && this.state.referralId !== this.props.item.referralId && this.state.referralId !== prevState.referralId) {
				this.loadContacts(this.state.referralId);
			}
		}
	}

	loadContacts(refferalId: number) {
		request<List<User>>('userList', {companyId: refferalId, role: UserRole.External, count:10000}).then(
			(response: List<User>) => {
				
				this.setState({
					contacts: response.list.filter(u => u.banned == false)
				});

				const formik = this.form.current;
				let _list = response.list.filter(u => u.banned == false);
				if (formik && _list.length > 0) {
					
					formik.setFieldValue('referralContactId', _list[0].id);
				}
			}
		);
	}
	
	addInjury():void {
		const item = this.props.item;
		const injuries = item.injuries.list;
		const lastElem = injuries[injuries.length - 1];

		let unclosedInjury = false;
		injuries.forEach(function(injury) {
			if (injury.injuryStatus !== 5) {
				return unclosedInjury = true;
			}
		});
		
		const condition = lastElem && lastElem.id !== -1 || lastElem === undefined;

		if (item && !unclosedInjury && condition ) {
			const newInjury = {
				id: -1, 
				employeId: item.id,
				employeeAttorney: {
					id: -1,
					attorneyType: AttorneyType.Employee
				},
				defenceAttorney: {
					id: -1,
					attorneyType: AttorneyType.Defence
				}
			};
			const newInjuries = {...item.injuries, count: item.injuries.count + 1, list: [newInjury, ...item.injuries.list]};
			this.props.itemActions.updateItem('employe', {injuries: newInjuries});
			this.setState({
				activeKey: 'injury-new'
			});

			this.props.history.replace('/employe-editor/' + this.props.item.id + '?case=new');
		} else if (unclosedInjury) {
			message.error('You should close current injury!');
		} 
	}
	
	updateInjury(oldInjury: Injury, newInjury: Injury): void {
		const injuryIndex = this.props.item.injuries.list.indexOf(oldInjury);
		const newInjuriesList = this.props.item.injuries.list.slice();
		
		newInjuriesList.splice(injuryIndex, 1, newInjury);
		
		this.props.itemActions.updateItem('employe', {
			injuries: {
				...this.props.item.injuries,
				list: newInjuriesList
			}
		});
		
		if(oldInjury.id === -1) {
			this.onChange('injury-' + newInjury.id);
		}
	}

	onCancel(injury: Injury) {
		const item = this.props.item;
		const injuries = item.injuries.list;
		
		if (injury.id === -1) {
			const newInjuries = {
				...item.injuries,
				count: item.injuries.count - 1,
				list: [...injuries.slice(1)]
			};
			
			this.props.itemActions.updateItem('employe', {injuries: newInjuries});
		} else if(injury.deleted) {
			const newInjuries = {
				...item.injuries,
				count: item.injuries.count - 1,
				list: injuries.filter((item) => item.id !== injury.id)
			};

			this.props.itemActions.updateItem('employe', {injuries: newInjuries});
		}
		this.onChange('general-tab');
	}
	
	onChange(activeKey) {
		this.setState({activeKey});
		
		if (activeKey == 'general-tab') {
			this.props.history.replace('/employe-editor/' + this.props.item.id);
			
			this.props.commentsObjectAction.setCommentsObject(null);
			
		} else if(activeKey == 'injury-new') {
			this.props.history.replace('/employe-editor/' + this.props.item.id + '?case=new');
		} else {
			let injuryId = +activeKey.split('-')[1];
			
			let vccCase: string | null = null;
			
			if (injuryId > 0) {
				const injury = this.props.item.injuries.list.find(injury => injury.id == injuryId);
				
				if(injury) {
					vccCase = injury.vccCase;
					this.props.history.replace('/employe-editor/' + this.props.item.id + '?case=' + vccCase);

					this.props.commentsObjectAction.setCommentsObject({ id: injury.id, objectType: 'injury', caption: 'Case' });

					request('viewCase', { id: injury.id });
				}
			}
		}
	};

	handleSubmit(values: EmployeeFormValues) {
		if (this.companyName != this.companyNameCurrentInputValue) {
			message.error('You should search and select company from available list')
		} else {
			const next = this.state.next;
			
			let item = { 
				...(this.props.item as any), 
				...values, 
				injuries: null,
				phone: phoneReplace(values.phone),
				mobile: phoneReplace(values.mobile)
			};
			item.isCompleted = true;
			
			this.saveItem(item).then((response: Employee) => {
				if (item.id < 0 && !next) {
					this.props.history.replace('/employe-editor/' + response.id);

					this.updateItem({
						...response,
						injuries: {
							count: 0,
							list: [{id: -1, employeId: response.id}],
							execution: 0,
							offset: 0
						}
					});

					this.setState({
						next: true,
						activeKey: 'injury-new'
					});

					this.hideSuccess();
				}
			});
		}
	}
	
	companyOnSelect(value, option) {
		const formik = this.form.current;
		
		if(formik) {
			formik.setFieldValue('companyId', +option.key);
			this.companyName = option.props.item.name;
			this.companyNameCurrentInputValue = option.props.item.name || '';
			if (option.props.item.referral) {
				formik.setFieldValue('referral', option.props.item.referral);
				formik.setFieldValue('referralId', option.props.item.referral.id);
				if (this.state.referralId !== option.props.item.referral.id) 
					this.setState({ referralId: option.props.item.referral.id });
			}

			formik.setFieldValue('payrollPeriod', option.props.item.payrollPeriod);
			formik.setFieldValue('startDate', option.props.item.startDate);

			//if (option.props.item.referral) {
			//	this.loadContacts(option.props.item.referral.id);
   //         }
		}
	}
	
	public render() {
		
		const item: Employee = this.props.item,
			injuryTypes: InjuryType[] = this.props.injuryTypes.items,
			injuryPendingReasons: InjuryPendingReason[] = this.props.injuryPendingReasons.items,
			user = this.props.user;
		
		const contacts = this.state.contacts;

		if (item && item.referralContactId>0) {
			const formik = this.form.current;
			
			if (formik !=null) {
				formik.setFieldValue('referralContactId', item.referralContactId);
			}
        }
		
		const commentObject: EmployeeCommentsObjectState.EmployeeCommentsObject | null = this.props.location.search ? this.props.commentsObject.item : null;
		
		const check = (name, form, count): boolean => form.errors[name] && form.touched[name] && count > 0;
		
		if (this.handleCheckItem() || this.state.next) {
			const injuries: Injury[] = item.injuries.list;
			
			const addTab: JSX.Element | null = item.id > 0 ? <button className="btn btn-default btn-sm" onClick={this.addInjury}><i className="fa fa-plus"></i></button> : null;
			
			let self = this;
			
			return (
				<div className="editor-page row">
					<div className={item.id > 0 ? 'col-sm-8' : 'col-sm-12'}>
						<div className="site-headline">
							<h1>{item.id > 0 ? ('Edit Employee ' + item.firstName + ' ' + item.lastName) : 'Add Employee'}</h1>
						</div>
						<Tabs className="employee-tabs" type="card" onChange={this.onChange} tabBarExtraContent={item.id > 0 && user && user.role !== UserRole.External && addTab} activeKey={this.state.activeKey}>
							<TabPane tab="General Information" key="general-tab">
								{this.props.user && this.props.user.role !== UserRole.External ? 
									<Formik
									initialValues={
										{
											...item,
											firstName: item.firstName || '',
											lastName: item.lastName || '',
											email: item.email || '',
											address: item.address || '',
											apartment: item.apartment || '',
											city: item.city || '',
											zip: item.zip || '',
											occupationId: item.occupationId || null,
											age: item.age || '',
											phone: phoneFormat(item.phone),
											mobile: phoneFormat(item.mobile),
											state: item.state || '',
											timeZone: item.timeZone || 0,
											salutation: item.salutation || 1,
											referralId: item.referralId || null,
											referralContactId: item.referralContactId || -1,
											payrollPeriod: item.payrollPeriod === undefined ? PayrollPeriod.Weekly : item.payrollPeriod,
											startDate: item.startDate === undefined ? DayOfWeek.Friday : item.startDate,
											workerComp: item.workerComp || WorkerComp.WorkersComp,
										}
									}
									onSubmit={this.handleSubmit}
									validationSchema={validationSchema}
									enableReinitialize={true}
									ref={this.form}
									render={(formikBag: FormikProps<EmployeeFormValues>) => (
										<Form id={'employee-form-' + item.id}>
											<ScrollToError {...formikBag} formId={'employee-form-' + item.id}/>
											<div className="row">
												<Field
													name="companyId"
													render={({ field, form }: FieldProps<EmployeeFormValues>) => (
														<div className={`form-group col-sm-6 ${form.errors.companyId && form.touched.companyId ? 'has-error' : ''}`}>
															<label>Company*</label>
															<div className="is-relative">
															<Autocomplete type="companyList"
																		  renderOption={(item) => <Option key={item.id} value={`${item.name}`} title={item.name} {...{item: item}}>{item.name}</Option>}
																		  onSelect={this.companyOnSelect}
																		  onChange={(value = '') => { 
																		  	if (value === '') {
																				formikBag.setFieldValue('companyId', null);
																				this.companyName = ''
																			}
																			this.companyNameCurrentInputValue = value;
																		  }}
																		  params={{
																			  employerOrReferral: true,
																			  companyType: SpecialCompanyType.Employer,
																			  withReferral: true
																		  }}
																		  value={item.companyName || ''}
																		  loadOnFocus={true}
																		  paramName="name"/>
																{form.touched.companyId && form.errors.companyId ? <div className="validation-message">{form.errors.companyId}</div> : ''}
															</div>	
														</div>
													)}
												/>
												<Field
													name="referralId"
													render={({ field, form }: FieldProps<EmployeeFormValues>) => (
														<div className={`form-group col-sm-6 ${form.errors.referralId && form.touched.referralId ? 'has-error' : ''}`}>
															<label>Referral Source*</label>
															<div className="is-relative">
																<Autocomplete type="companyList"
																              renderOption={(item) => <Option key={item.id} value={`${item.name}`} title={item.name} {...{item: item}}>{item.name}</Option>}
																              onSelect={(value, option) => {
																	              formikBag.setFieldValue('referralId', +option.key);
																	              if (self.state.referralId !== +option.key) {
																		              self.setState({ referralId: +option.key });
																	              }
																              }}
																              onChange={(value = '') => {
																	              if (value === '') {
																		              formikBag.setFieldValue('referralId', null);
																		              formikBag.setFieldValue('referralContactId', -1);
																		              formikBag.setFieldValue('referralContact', null);
																		              self.setState({contacts: []});
																	              }
																              }}
																              params={{
																	             companyType: SpecialCompanyType.Referral
																              }}
																			  value={formikBag.values.referral ? formikBag.values.referral.name : item.referral ? item.referral.name : ''}
																	          loadOnFocus={true}
																              paramName="name"/>
																{form.touched.referralId && form.errors.referralId ? <div className="validation-message">{form.errors.referralId}</div> : ''}
															</div>
														</div>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="workerComp"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Workers Comp or Non-Occupational"
															render={({field, form}) =>
																<select className="form-control" id={`workerComp`} {...field}>
																	<option value={WorkerComp.WorkersComp}>Workers Comp</option>
																	<option value={WorkerComp.NonOccupational}>Non-Occupational</option>
																</select>
															}
														/>
													)}
												/>
												<Field
													name="referralContactId"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => 
														<FormikInput fieldProps={fieldProps} title="Referral Contact*" render={({field, form}) => 	
															<select className="form-control" id={`referralContactId`} disabled={!formikBag.values.referralId} {...field}>
																{contacts.map(c =>
																	<option value={c.id} key={c.id}>{getUserNameWithEmail(c)}</option>)}
															</select>
														}/>
													}
												/>
											</div>
											{/*<div className="row">
												<Field
													name="payrollPeriod"
													render={({field, form}: FieldProps<EmployeeFormValues>) => (
														<div className={`form-group col-sm-6 ${form.errors.payrollPeriod && form.touched.payrollPeriod ? 'has-error' : ''}`}>
															<label>Payroll Period*</label>
															<div className="is-relative">
																<select className="form-control"  id={`payrollPeriod`} value={item.payrollPeriod} {...field}>
																	<option disabled value={-1}>Select payroll period</option>
																	<option value={PayrollPeriod.Weekly}>Weekly</option>
																	<option value={PayrollPeriod.ByWeekly}>By-Weekly</option>
																</select>
																{form.touched.payrollPeriod && form.errors.payrollPeriod ? <div className="validation-message">{form.errors.payrollPeriod}</div> : ''}
															</div>
														</div>
													)}
												/>
												<Field
													name="startDate"
													render={({field, form}: FieldProps<EmployeeFormValues>) => (
														<div className={`form-group col-sm-6 ${form.errors.startDate && form.touched.startDate ? 'has-error' : ''}`}>
															<label>NP Earliest Start Date*</label>
															<div className="is-relative">
																<select className="form-control"  id={`startDate`} value={item.startDate} {...field}>
																	<option disabled value={-1}>Select start date</option>
																	<option value={DayOfWeek.Sunday}>Sunday</option>
																	<option value={DayOfWeek.Monday}>Monday</option>
																	<option value={DayOfWeek.Tuesday}>Tuesday</option>
																	<option value={DayOfWeek.Wednesday}>Wednesday</option>
																	<option value={DayOfWeek.Thursday}>Thursday</option>
																	<option value={DayOfWeek.Friday}>Friday</option>
																	<option value={DayOfWeek.Sunday}>Sunday</option>
																</select>
																{form.touched.startDate && form.errors.startDate ? <div className="validation-message">{form.errors.startDate}</div> : ''}
															</div>
														</div>
													)}
												/>
											</div>*/}
											<div className="row">
												<Field
													name="salutation"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Salutation"
															render={({field, form}) =>
																<select className="form-control" id="salutation" {...field}>
																	<option value={1}>Mr.</option>
																	<option value={2}>Ms.</option>
																	<option value={3}>Mrs.</option>
																	<option value={4}>Dr.</option>
																</select>
															}
														/>
													)}
												/>
											</div>
											<div className="row">
												<Field name="firstName" render={(fieldProps: FieldProps<EmployeeFormValues>) => (
													<FormikInput fieldProps={fieldProps} title="First Name*"/>
												)}
												/>
												<Field name="lastName" render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput fieldProps={fieldProps} title="Last Name*"/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="address"
													render={(fieldProps: FieldProps<EmployeeFormValues>) =>
														<FormikInput fieldProps={fieldProps} title="Address*"/>
													}
												/>
												<Field
													name="apartment"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Apartment Number(#)"
															render={({ field, form }: FieldProps<EmployeeFormValues>) => <div className="input-group w-100">
																<input className="form-control" type="text" id="apartment" {...field}/>
																<div className="input-group-btn">
																	<GoogleMapButton state={formikBag.values.state} address={formikBag.values.address} city={formikBag.values.city} zip={formikBag.values.zip}/>
																</div>
															</div>}
														/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="city"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="City*"
														/>
													)}
												/>
												<Field
													name="state"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="State*"
															render={({field, form}) => <State value={field.value} onChange={state => formikBag.setFieldValue('state', state)} abbreviation={true}/>}
														/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="zip"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Zip*"
															render={(fieldProps: FieldProps<EmployeeFormValues>) => {
																return <ZipAutocomplete
																	fieldProps={fieldProps}
																	onSelect={(value, option) => {
																		formikBag.setValues({
																			...formikBag.values,
																			zip: option.props.item.zip,
																			city: option.props.item.city,
																			state: option.props.item.stateName
																		});
																	}}
																/>
															}}
														/>
													)}
												/>
												<Field
													name="timeZone"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Time Zone"
															render={({field, form}) => <StateTimeZoneSelect 
																value={field.value} state={formikBag.values.state} 
																onChange={ timeZone => {formikBag.setFieldValue('timeZone', timeZone)}}
															/>}
														/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="email"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput fieldProps={fieldProps} title="Email"/>
													)}
												/>
												<Field
													name="gender"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Gender"
															render={({field, form}) => 
																<select className="form-control" id="gender" {...field}>
																	<option value="0">Male</option>
																	<option value="1">Female</option>
																</select>
															}
														/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="occupationId"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput
															fieldProps={fieldProps}
															title="Occupation"
															render={({field, form}) =>
																<select className="form-control" id="occupationId" {...field}>
																	<option value={0} key={0} disabled={true}>Select occupation</option>
																	{this.props.occupations.items.map(o =>
																		<option value={o.id} key={o.id}>{o.name}</option>)}
																</select>
															}
														/>
													)}
												/>
												<Field
													name="age"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput fieldProps={fieldProps} title="Age"/>
													)}
												/>
											</div>
											<div className="row">
												<Field
													name="phone"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput 
															fieldProps={fieldProps} 
															title="Phone"
															render={({field, form}) =>
																<MaskedInput
																	mask={phoneMask}
																	className="form-control"
																	placeholder="+1 (___) ___-____"
																	guide={true}
																	id="phone"
																	{...field}
																/>
															}
														/>
													)}
												/>
												<Field
													name="mobile"
													render={(fieldProps: FieldProps<EmployeeFormValues>) => (
														<FormikInput fieldProps={fieldProps} title="Mobile"
														             render={({field, form}) =>
															             <MaskedInput
																             mask={phoneMask}
																             className="form-control"
																             placeholder="+1 (___) ___-____"
																             guide={true}
																             id="mobile"
																             {...field}/>
														             }
														/>
													)}
												/>
											</div>
											<Field
												name="languages"
												render={({field, form}: FieldProps<EmployeeFormValues>) => (
													<React.Fragment>
														<label>Languages Spoken</label>
														<FormikList
															field={field}
															formikBag={formikBag}
															equalityChecker={(item, newItem) => item.languageId == newItem.languageId}
															renderItem={(item, removeItem) => <div className="inline-select-item inline-select-item_witm-mb" key={item.id}>
																<i className="fa fa-times inline-select-item__remove" onClick={removeItem}/>
																<span>{item.language.name}</span>
															</div>}
															renderInput={addNewItem => <div className="form-group">
																<Autocomplete 
																	type="languageList"
																	renderOption={(autocompleteItem) =>
																		<Option
																			{...{
																				key: autocompleteItem.id,
																				value: autocompleteItem.id + '',
																				title: autocompleteItem.name,
																				item: autocompleteItem
																			}}>{autocompleteItem.name}</Option>
																	}
																	onSelect={(value, option) => {
																		addNewItem({
																			id: -1,
																			employeId: item.id,
																			employe: item,
																			language: option.props.item,
																			languageId: +value
																		});
																	}}
																	value=""
																	paramName="text"
																	isClear={true}/>
															</div>}
														/>
													</React.Fragment>)}
											/>

											{item.id > 0 && <div className="row"><div className="form-group col-sm-6"><label>VCC Code</label><br/>{item.vccCode}</div></div>}
											<div className="text-center form-group">
												<Button isLoading={this.state.isLoading}>Save</Button>
												<button type="button" className="btn btn-danger" onClick={this.handlerBack}>Cancel</button>
											</div>
											{this.messages()}
										</Form>
									)}
								/>
								: <EmployeeEditorView item={item}/>}
							</TabPane>
							
							{injuries.map((injury: Injury, index: number) => {
								return <TabPane tab={injury.id > 0 ? <React.Fragment>{injury.injuryStatus === InjuryStatus.Closed && <Icon type="lock"></Icon>}Case# - {injury.vccCase}</React.Fragment> : 'Injury'} disabled={item.id < 0 && !this.state.next} key={`injury-${injury.id > 0 ? injury.id : 'new'}`}>
										<InjuryForm 
											item={injury} 
											injuryTypes={injuryTypes} 
											pendingReasons={injuryPendingReasons}
											updateInjury={this.updateInjury}
											onCancel={this.onCancel} 
											employee={item}
											referral={item.referral}
										/>
									</TabPane>
							})}
						</Tabs>
					</div>
					{(item.id > 0) && (this.props.user && this.props.user.role !== UserRole.External) && <div className="col-sm-4 editor-page__comments-container">
						<div className="editor-page__comments">
							<Tabs>
								{commentObject ?
									<TabPane tab="Case Notes" key="notes">
										<CommentTree objectType={commentObject.objectType} objectId={commentObject.id} withoutAddButton={true} insertBefore={true}/>
									</TabPane>
									: <TabPane tab="Employee Notes" key="employe">
										<CommentTree objectType="employe" objectId={item.id} withoutAddButton={true} insertBefore={true}/>
									</TabPane>
								}
								{item.companyId && <TabPane tab="Employer Notes" key="employerNotes">
									<CommentTree objectType="company" objectId={+item.companyId} withoutAddButton={true} insertBefore={true}/>
								</TabPane>}
								{item.referralId && <TabPane tab="Referral Notes" key="referralNotes">
									<CommentTree objectType="company" objectId={+item.referralId} withoutAddButton={true} insertBefore={true}/>
								</TabPane>}
							</Tabs>
						</div>
					</div>}
				</div>	
			)
		}

		return <Loading/>
	}
}

export default withRouter(connect<ReduxProps, any, any>(
	(state: ApplicationState) => ({
		...state.employe,
		injuryTypes: state.injuryTypes,
		injuryPendingReasons: state.injuryPendingReasons,
		occupations: state.occupations,
		commentsObject: state.employeeCommentsObject,
		user: state.login.user
	}),
	(dispatch) => ({
		itemActions: bindActionCreators(PageState.getActionCreators(), dispatch),
		pagesActions: bindActionCreators(ItemsState.getActionCreators(), dispatch),
		commentsObjectAction: bindActionCreators(EmployeeCommentsObjectState.actionCreators, dispatch)
	})
)(EmployeeEditor));